import React, { useContext, useState, useEffect, useRef } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { MyThemeContext } from "../../../context/theme-context";

import {
	Box,
	Typography,
	Paper,
	Select,
	MenuItem,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TextField,
	TablePagination,
	useMediaQuery,
	IconButton,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ClearIcon from "@mui/icons-material/Clear";

import TableTokenValuationRow from "./TableTokenValuationRow";

function TableTokenValuation() {
	const { themeContext } = useContext(MyThemeContext);
	const { realTokens, DEFAULT_PROPERTY_TYPE, settingsStats, setSettingsStats } = useContext(SettingsContext);
	const [selectedFullName, setSelectedFullName] = useState(() => {
		return sessionStorage.getItem("Stats_selectedFullName") || "";
	});

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const selectedPropertyTypeChange = (newValue) => {
		setSettingsStats((prevSettings) => ({
			...prevSettings,
			selectedPropertyType: newValue,
		}));
	};

	const [sortBy, setSortBy] = useState({ field: "strInitialLaunchDate", order: "desc", type: "text" });

	// ---------------------------------------------------------
	// ---- Timer pour mise à jour de selectedFullName    ----
	// -------------------------------------------------------
	const TIMEOUT_REFRESH = 2000; // Timer pour le refresh des offres en millisecondes
	const [state, setState] = useState(false);
	const counter = useRef(1);
	useEffect(() => {
		const newValue = sessionStorage.getItem("Stats_selectedFullName");
		if (!newValue) setSelectedFullName("");
		else if (newValue !== selectedFullName) setSelectedFullName(newValue);
		counter.current += 1;
		const timer = setTimeout(() => setState({ num: counter.current }), TIMEOUT_REFRESH);
		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	useEffect(() => {
		sessionStorage.setItem("Stats_selectedFullName", selectedFullName);
	}, [selectedFullName]);

	if (!realTokens) return <></>;
	if (settingsStats.selectedPropertyType === null) return <></>;
	if (!DEFAULT_PROPERTY_TYPE) return <></>;
	if (realTokens.list.length === 0) return <></>;
	let tokens = [
		...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
	];

	if (settingsStats.selectedPropertyType > 0) tokens = tokens.filter((data) => data.propertyType === settingsStats.selectedPropertyType);
	if (selectedFullName) tokens = tokens.filter((data) => data.fullName.toLowerCase().includes(selectedFullName.toLowerCase()));
	// console.log("tokens:", tokens);

	const cityCounts = tokens.reduce((acc, token) => {
		const city = token.fullName.split(",")[1].trim(); // Extracts the city name from the fullName
		if (!acc[city]) {
			// If the city hasn't been encountered before, initialize it
			acc[city] = { count: 0, label: city, tokens: [] };
		}
		acc[city].count += 1; // Increment the count for this city
		return acc;
	}, {});

	// Calculate the aggregate data
	const allCities = {
		label: "All Cities",
		count: 0,
	};

	// Fill the aggregate data with total counts and all tokens
	Object.values(cityCounts).forEach((city) => {
		allCities.count += city.count;
	});

	const cityMap = Object.entries(cityCounts)
		.sort((a, b) => a[0].localeCompare(b[0]))
		.map(([city, data]) => ({
			label: data.label,
			value: data.count,
		}));

	// Prepend the summary of all cities to the cityMap
	cityMap.unshift({
		label: allCities.label,
		value: allCities.count,
		tokens: allCities.tokens,
	});

	const handleSort = (field, type) => {
		setSortBy((prevSortBy) => ({
			field,
			order: prevSortBy.field === field && prevSortBy.order === "desc" ? "asc" : "desc",
			type,
		}));
	};

	tokens.forEach((x) => {
		x.init_price = x.historic.init_price;
		x.init_yield = x.historic.init_yield;
		x.strInitialLaunchDate = x.initialLaunchDate.date.slice(0, 10);
		x.strRentStartDate = x.rentStartDate.date.slice(0, 10);

		// Step 1: Sort prices
		const sortedPrices = x.historic.prices.sort((a, b) => new Date(a.timsync) - new Date(b.timsync));
		// Step 2: Function to find the most recent price
		function findMostRecentPrice(timsync) {
			let mostRecentPrice = null;
			for (let price of sortedPrices) {
				if (new Date(price.timsync) <= new Date(timsync)) {
					mostRecentPrice = price.price;
				} else {
					break;
				}
			}
			return mostRecentPrice;
		}
		// Step 3: Merge data
		const updatedYields = x.historic.yields.map((yieldEntry) => {
			const mostRecentPrice = findMostRecentPrice(yieldEntry.timsync);
			return {
				...yieldEntry,
				price: mostRecentPrice,
			};
		});
		x.valuation = x.tokenPrice - x.init_price;
		x.rentEarned = 0;
		let days_rented = 0;
		if ((x.canal === "exit_complete")) {
			// console.log("updatedYields[updatedYields.lenght-1]",updatedYields.length, updatedYields[updatedYields.length - 1]);
			updatedYields[updatedYields.length-1].days_rented=0
		}
		updatedYields.forEach((a) => {
			if (a.days_rented > 0) x.rentEarned += ((a.yield * a.price) / 36500) * a.days_rented;
			days_rented += a.days_rented;
		});

		x.roc = (x.valuation + x.rentEarned) / x.init_price;
		x.newYield = ((((x.valuation + x.rentEarned) / days_rented) * 365) / x.init_price) * 100;
		x.diffYield = x.newYield - x.init_yield;
		x.rentedRatio = x.rentedUnits / x.totalUnits;
		x.updatedYields = updatedYields;

		// console.log("x.roc", (x.valuation + x.rentEarned) / x.init_price, x.valuation, x.rentEarned, x.init_price);
		return x;
	});

	const sortedTokens = [...Object.values(tokens)].sort((a, b) => {
		const order = sortBy.order === "asc" ? 1 : -1;
		if (sortBy.type === "number") return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
		if (sortBy.type === "text") return order * a[sortBy.field].localeCompare(b[sortBy.field]);
		else return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
	});

	if (sortedTokens.length / rowsPerPage < page && page > 0) handleChangePage(page, page - 1);

	// console.log("sortBy", sortBy);

	return (
		<Paper elevation={3} sx={{ m: 1, minWidth: 800, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
			{/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}></Box> */}
			<Paper elevation={6} sx={{ m: 1, minWidth: 800, border: 0, borderColor: "primary.main", borderRadius: 4 }}>
				<Box
					sx={{
						flexWrap: "wrap",
						m: 1,
						p: 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 4,
						display: "flex",
						alignItems: "center",
						justifyContent: "start",
					}}
				>
					<Typography variant='h5' sx={{ m: 0, color: "primary.main" }}>
						Search
					</Typography>
					<TextField
						sx={{ mt: 0, ml: 1, minWidth: 200 }}
						label='Search in API fullName'
						onChange={(e) => setSelectedFullName(e.target.value)}
						variant='outlined'
						type='text'
						size='small'
						value={selectedFullName}
						InputProps={{
							// Utilisez "InputProps" avec un "P" majuscule
							endAdornment: selectedFullName ? ( // Condition modifiée pour une meilleure clarté
								<IconButton onClick={() => setSelectedFullName("")}>
									<ClearIcon />
								</IconButton>
							) : null,
							style: {
								// Les styles doivent aussi être déplacés ici si spécifiques aux composants d'input
								textAlign: "center",
								verticalAlign: "center",
								padding: "0px 0px",
							},
						}}
					/>
					<Typography variant='h5' sx={{ ml: 2, color: "primary.main" }}>
						Property Type
					</Typography>
					<Select
						sx={{ mt: 0, ml: 1, minWidth: 125 }}
						value={settingsStats ? settingsStats.selectedPropertyType : 0}
						onChange={(e) => selectedPropertyTypeChange(e.target.value)}
						size='small'
					>
						{DEFAULT_PROPERTY_TYPE.filter((p) => !p.label.startsWith("Text_")).map((p) => (
							<MenuItem key={p.index} value={p.index}>
								{p.label}
							</MenuItem>
						))}
					</Select>
					<TablePagination
						sx={{ ml: "auto", minWidth: 125 }}
						rowsPerPageOptions={[10, 25, 50, 100, 500]}
						component='div'
						count={sortedTokens.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label='simple table'>
							<TableHead>
								<TableRow>
									<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}  colSpan={2}>
										RealT
									</TableCell>
									<TableCell
										align='left'
										sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("fullName", "text")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
											Token Name
											{sortBy.field === "fullName" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>
									<TableCell
										align='right'
										sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("totalInvestment", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											Investment
											{sortBy.field === "totalInvestment" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>
									<TableCell
										align='right'
										sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("init_price", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											Init Price
											{sortBy.field === "init_price" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>
									<TableCell
										align='right'
										sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("init_yield", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											Init Yield
											{sortBy.field === "init_yield" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>
									{!isMatch && (
										<TableCell
											align='right'
											sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
											onClick={() => handleSort("strInitialLaunchDate", "text")}
										>
											<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
												{!isMatch ? "Acquisition" : "Acq."}
												{sortBy.field === "strInitialLaunchDate" &&
													(sortBy.order === "asc" ? (
														<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
													) : (
														<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
													))}
											</Box>
										</TableCell>
									)}
									<TableCell
										align='right'
										sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("strRentStartDate", "text")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											Rent Start
											{sortBy.field === "strRentStartDate" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>
									<TableCell
										align='right'
										sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("tokenPrice", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											Token Price
											{sortBy.field === "tokenPrice" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>
									<TableCell
										align='right'
										sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("annualPercentageYield", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											Token Yield
											{sortBy.field === "annualPercentageYield" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>
									<TableCell
										align='right'
										sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("rentedRatio", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											Rented Units
											{sortBy.field === "rentedRatio" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>
									<TableCell
										align='right'
										sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("valuation", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											{!isMatch ? "Appreciation" : "App."}
											{sortBy.field === "valuation" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>
									<TableCell
										align='right'
										sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("rentEarned", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											{!isMatch ? "Rent Collected" : "Rent Coll."}

											{sortBy.field === "rentEarned" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>
									<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }} onClick={() => handleSort("roc", "number")}>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											RoC
											{sortBy.field === "roc" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>
									<TableCell
										align='right'
										sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("newYield", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											RT Yield
											{sortBy.field === "newYield" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>
									<TableCell
										align='right'
										sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("diffYield", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											Diff Yield
											{sortBy.field === "diffYield" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{sortedTokens
									// .sort((a, b) => a.initialLaunchDate.date.localeCompare(b.initialLaunchDate.date))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((token) => (
										<TableTokenValuationRow key={token.id} token={token} />
									))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Paper>
		</Paper>
	);
}

export default TableTokenValuation;
