import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../context/settings-context";
import { SnackbarContext } from "../../context/customsnackbar-context";
import { MyThemeContext } from "../../context/theme-context";

import { Badge, Box, Paper, Typography, useMediaQuery, CircularProgress } from "@mui/material";
import { green, red, pruple } from "@mui/material/colors";

// import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
// import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import StorefrontIcon from "@mui/icons-material/Storefront";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SendIcon from "@mui/icons-material/Send";

import PurchasesOffersHead from "./PurchasesOffers/PurchasesOffersHead";
import WalletPurchasesOffersHead from "./PurchasesOffers/WalletPurchasesOffersHead";
// import TokensTableHead from "./PurchasesOffers/TokensTableHead";

const isCity = false;

function PurchasesOffers() {
	const { realTokens, holderWallets, balanceWallets, blockchainsClient } = useContext(SettingsContext);
	const { settingsMarket, setSettingsMarket, settingsWallet } = useContext(SettingsContext);
	const { themeContext } = useContext(MyThemeContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const [tokens, setTokens] = useState(null);
	const [sortedOffers, setSortedOffers] = useState(null);
	const [sortedWalletOffers, setSortedWalletOffers] = useState(null);
	const [sortedWalletOffersCount, setSortedWalletOffersCount] = useState(0);
	const [offers, setOffers] = useState(null);
	const [cityList, setCityList] = useState(null);
	const [coinsList, setCoinsList] = useState(null);
	const [walletCoinsList, setWalletCoinsList] = useState(null);

	const [newOrModifyOffers, setNewOrModifyOffers] = useState([]);
	const [idMax, setIdMax] = useState(1);

	const [allOffers, setAllOffers] = useState(true);
	const [walletOffers, setWalletOffers] = useState(false);
	const [settingOffers, setSettingOffers] = useState(false);
	const [helpOffers, setHelpOffers] = useState(false);
	const [shopping, setShopping] = useState(false);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	useEffect(() => {
		let settings = JSON.parse(JSON.stringify(settingsMarket));
		if (!settings.PurchaseOffers) {
			settings.PurchaseOffers = { unselectedCity: [], showRentStart: true, showPosition: true, showRented: true };
			setSettingsMarket(settings);
		}
		if (!settings.WalletPurchaseOffers) {
			settings.WalletPurchaseOffers = { unselectedCity: [], showRentStart: true, showPosition: true, showRented: true };
			setSettingsMarket(settings);
		}
	}, [settingsMarket]);

	useEffect(() => {
		if (realTokens) {
			let cptTokens = [
				...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0), //&& data.productType !== "equity_token"),
			];

			if (balanceWallets) {
				const testContractxDai = (token, address) => {
					if (token.gnosisContract === address || token.xDaiContract === address) return true;
					else return false;
				};
				const testContractEth = (token, address) => {
					if (token.ethereumContract === address) return true;
					else return false;
				};

				cptTokens.forEach((token) => {
					token.walletsAmount = 0;
					token.walletAmount = 0;
					token.whiteList = false;
					token.strRentStartDate = token.rentStartDate.date.slice(0, 10);
					if (holderWallets) {
						// if (holderWallets.filter((holderWallet) => holderWallet.address===settingsWallet.selectedWallet)[0]) {
						// if (holderWallets.filter((holderWallet) => holderWallet.checked)[0]) {
						holderWallets
							// .filter((holderWallet) => holderWallet.address === settingsWallet.selectedWallet)
							.forEach((holderWallet) => {
								balanceWallets[holderWallet.address].tokens["xdai"].tokens
									.filter((t) => testContractxDai(token, t.token.id))
									.forEach((t) => {
										// console.log("token to find", t);
										if (holderWallet.address === settingsWallet.selectedWallet) token.walletAmount += parseFloat(t.amount);
										token.walletsAmount += parseFloat(t.amount);
										// console.log("Add xdai wallets token", token.shortName, t, token.walletsAmount, holderWallet.address);
									});
								balanceWallets[holderWallet.address].tokens["eth"].tokens
									.filter((t) => testContractEth(token, t.token.id))
									.forEach((t) => {
										if (holderWallet.address === settingsWallet.selectedWallet) token.walletAmount += parseFloat(t.amount);
										token.walletsAmount += parseFloat(t.amount);
									});
								if (balanceWallets[holderWallet.address].tokens["rmm"].tokens) {
									balanceWallets[holderWallet.address].tokens["rmm"].tokens
										.filter((t) => testContractxDai(token, t.reserve.underlyingAsset))
										.forEach((t) => {
											token.walletsAmount += parseFloat(t.currentATokenBalance / 10 ** t.reserve.decimals);
											if (holderWallet.address === settingsWallet.selectedWallet)
												token.walletAmount += parseFloat(t.currentATokenBalance / 10 ** t.reserve.decimals);
										});
								}
								if (balanceWallets[holderWallet.address].tokens["rmmv3"].tokens) {
									balanceWallets[holderWallet.address].tokens["rmmv3"].tokens
										.filter((t) => testContractxDai(token, t.token.id))
										.forEach((t) => {
											token.walletsAmount += parseFloat(t.amount / 10 ** t.token.decimals);
											if (holderWallet.address === settingsWallet.selectedWallet) token.walletAmount += parseFloat(t.amount / 10 ** t.token.decimals);
										});
								}
								if (balanceWallets[holderWallet.address].tokens["pool"].tokens) {
									balanceWallets[holderWallet.address].tokens["pool"].tokens
										.filter((t) => testContractxDai(token, t.pair.token0.id))
										.forEach((t) => {
											token.walletsAmount += parseFloat((t.liquidityTokenBalance * t.pair.token0.liquidity) / t.pair.totalSupply);
											if (holderWallet.address === settingsWallet.selectedWallet)
												token.walletAmount += parseFloat((t.liquidityTokenBalance * t.pair.token0.liquidity) / t.pair.totalSupply);
											// console.log("Add pool0 wallets token", token.shortName, token.walletsAmount);
										});
									balanceWallets[holderWallet.address].tokens["pool"].tokens
										.filter((t) => testContractxDai(token, t.pair.token1.id))
										.forEach((t) => {
											token.walletsAmount += parseFloat((t.liquidityTokenBalance * t.pair.token1.liquidity) / t.pair.totalSupply);
											if (holderWallet.address === settingsWallet.selectedWallet)
												token.walletAmount += parseFloat((t.liquidityTokenBalance * t.pair.token1.liquidity) / t.pair.totalSupply);
											// console.log("Add pool1 wallets token", token.shortName, token.walletsAmount);
										});
								}
								if (balanceWallets[holderWallet.address].whiteList.tokens) {
									balanceWallets[holderWallet.address].whiteList.tokens
										.filter((t) => testContractxDai(token, t))
										.forEach((t) => {
											token.whiteList = true;
										});
								}
							});
						// }
					}
				});
				setTokens(cptTokens);
			}
		}
	}, [realTokens, settingsWallet.selectedWallet]);

	useEffect(() => {
		if (!tokens) return;
		const testContractxDai = (token, address) => {
			if (token.gnosisContract === address || token.xDaiContract === address) return true;
			else return false;
		};
		let offers_swapcat = settingsMarket.SwapCatOffersList.filter((offer) => offer.token_to_buy !== null && offer.token_to_pay !== null);
		offers_swapcat = offers_swapcat.map((offer) => {
			return {
				...offer,
				sc: "swapcat",
				token: tokens ? tokens.filter((token) => testContractxDai(token, offer.token_to_buy))[0] : null,
			};
		});
		let offers_yam = settingsMarket.YamOffersList.filter(
			(offer) =>
				offer.token_to_buy !== null && offer.token_to_pay !== null && offer.buy_holder_address === "0x0000000000000000000000000000000000000000"
		);
		offers_yam = offers_yam.map((offer) => {
			return {
				...offer,
				sc: "yam",
				token: tokens ? tokens.filter((token) => testContractxDai(token, offer.token_to_buy))[0] : null,
			};
		});
		let offers = offers_swapcat.concat(offers_yam);

		// ------------------------------------------------------------
		// -- Calculate and filter 'offers' for PurchasesOffersHead --
		// ------------------------------------------------------------
		{
			offers.forEach((offer) => {
				if (offer.token) {
					offer.shortName = offer.token.shortName;
					offer.fullName = offer.token.fullName;
					offer.city = offer.token.fullName.split(",").map((part) => part.trim())[1];
					offer.tokenPrice = offer.token.tokenPrice;
					offer.position = offer.token.walletsAmount;
					offer.positionWallet = offer.token.walletAmount ? offer.token.walletAmount : 0;
					offer.marketplaceLink = offer.token.marketplaceLink;
					offer.rentStartDate = offer.token.rentStartDate.date.slice(0, 10);
					offer.annualPercentageYield = offer.token.annualPercentageYield;
					offer.whiteList = offer.token.whiteList;

					offer.token_value_usd = 0;
					offer.token_to_pay_img = "./images/unknown.png";
					offer.token_to_pay_name = "unknown ???";
					const tokenToPaid = Object.values(blockchainsClient[settingsWallet.selectedBlockchain].coinList).find(
						(coin) => coin.address.toLowerCase() === offer.token_to_pay.toLowerCase()
					);
					if (!tokenToPaid) {
						offer.token_to_pay = null;
						// console.log("tokenToPaid unKnown ?", offer);
					}
					if (tokenToPaid) {
						offer.token_to_pay_img = tokenToPaid.imgToken;
						offer.token_to_pay_name = tokenToPaid.name;
						offer.token_value_usd = offer.token_value * tokenToPaid.toUSD;
					}
					offer.token_ratio = (offer.token_value_usd / offer.tokenPrice) * 1;
					offer.token_newYield = (offer.annualPercentageYield * offer.tokenPrice) / offer.token_value_usd / 100;
					offer.token_rented = offer.token.rentedUnits / offer.token.totalUnits;
					if (!offer.token_rented) offer.token_rented = 1;
					offer.last_pricing_days = -1;
					offer.nb_pricing = 0;
					if (offer.token.historic) {
						offer.nb_pricing = offer.token.historic.prices.length - 1;
						const prisingDate = offer.token.historic.prices[offer.token.historic.prices.length - 1].timsync;
						const prisingDateObj = new Date(prisingDate);
						const currentDate = new Date();
						const timeDifference = currentDate - prisingDateObj;
						const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
						offer.last_pricing_days = daysDifference;
						if (offer.last_pricing_days < 0) offer.last_pricing_days = -1;
					}
					if (offer.token.shortName === "RWA Holdings SA") offer.token.whiteList = true;
				}
			});
			offers = offers.filter((offer) => offer.token_to_pay !== null);
			setOffers(offers);

			// Filtrage des offres en fonctions des reglages
			let sortedOffers = JSON.parse(JSON.stringify(offers));
			sortedOffers = sortedOffers.filter((offer) => offer.token_value_usd > 0);
			if (settingsMarket.PurchaseOffers.unselectedCity)
				sortedOffers = sortedOffers.filter((offer) => {
					const city = offer.fullName.split(",")[1].trim();
					return !settingsMarket.PurchaseOffers.unselectedCity.includes(city);
				});
			if (settingsMarket.PurchaseOffers.unselectedCoins)
				sortedOffers = sortedOffers.filter((offer) => {
					const coin = offer.token_to_pay_name;
					// console.log(coin,offer)
					return !settingsMarket.PurchaseOffers.unselectedCoins.includes(coin);
				});
			if (settingsMarket.PurchaseOffers.unselectPropertyType)
				sortedOffers = sortedOffers.filter((offer) => {
					const propertyType = offer.token.propertyType;
					return !settingsMarket.PurchaseOffers.unselectPropertyType.includes(propertyType);
				});
			if (settingsMarket.PurchaseOffers.isPosition)
				sortedOffers = sortedOffers.filter(
					(offer) => offer.position >= settingsMarket.PurchaseOffers.minPosition && offer.position < settingsMarket.PurchaseOffers.maxPosition
				);
			if (settingsMarket.PurchaseOffers.isOfferAmount)
				sortedOffers = sortedOffers.filter(
					(offer) =>
						offer.token_amount >= settingsMarket.PurchaseOffers.minOfferAmount && offer.token_amount < settingsMarket.PurchaseOffers.maxOfferAmount
				);
			if (settingsMarket.PurchaseOffers.isRatioPrice)
				sortedOffers = sortedOffers.filter(
					(offer) =>
						offer.token_ratio * 100 >= settingsMarket.PurchaseOffers.minRatioPrice &&
						offer.token_ratio * 100 < settingsMarket.PurchaseOffers.maxRatioPrice
				);
			if (settingsMarket.PurchaseOffers.isRealtPrice)
				sortedOffers = sortedOffers.filter(
					(offer) => offer.tokenPrice >= settingsMarket.PurchaseOffers.minRealtPrice && offer.tokenPrice < settingsMarket.PurchaseOffers.maxRealtPrice
				);
			if (settingsMarket.PurchaseOffers.isRealtYield)
				sortedOffers = sortedOffers.filter(
					(offer) =>
						offer.annualPercentageYield >= settingsMarket.PurchaseOffers.minRealtYield &&
						offer.annualPercentageYield < settingsMarket.PurchaseOffers.maxRealtYield
				);
			if (settingsMarket.PurchaseOffers.isNewPrice)
				sortedOffers = sortedOffers.filter(
					(offer) =>
						offer.token_value_usd >= settingsMarket.PurchaseOffers.minNewPrice && offer.token_value_usd < settingsMarket.PurchaseOffers.maxNewPrice
				);
			if (settingsMarket.PurchaseOffers.isNewYield)
				sortedOffers = sortedOffers.filter(
					(offer) =>
						offer.token_newYield * 100 >= settingsMarket.PurchaseOffers.minNewYield &&
						offer.token_newYield * 100 < settingsMarket.PurchaseOffers.maxNewYield
				);
			if (settingsMarket.PurchaseOffers.isRented)
				sortedOffers = sortedOffers.filter(
					(offer) =>
						offer.token_rented * 100 >= settingsMarket.PurchaseOffers.minRented && offer.token_rented * 100 < settingsMarket.PurchaseOffers.maxRented
				);
			if (settingsMarket.PurchaseOffers.isLastPricingDays)
				sortedOffers = sortedOffers.filter(
					(offer) =>
						offer.last_pricing_days >= settingsMarket.PurchaseOffers.minLastPricingDays &&
						offer.last_pricing_days < settingsMarket.PurchaseOffers.maxLastPricingDays
				);
			if (settingsMarket.PurchaseOffers.isNbPricing)
				sortedOffers = sortedOffers.filter(
					(offer) => offer.nb_pricing >= settingsMarket.PurchaseOffers.minNbPricing && offer.nb_pricing < settingsMarket.PurchaseOffers.maxNbPricing
				);
			if (settingsMarket.PurchaseOffers.isRentStart)
				sortedOffers = sortedOffers.filter((offer) => new Date(offer.rentStartDate).getTime() <= new Date().getTime());
			if (settingsMarket.PurchaseOffers.isnotRentStart)
				sortedOffers = sortedOffers.filter((offer) => new Date(offer.rentStartDate).getTime() >= new Date().getTime());
			if (settingsMarket.PurchaseOffers.isWhiteList) sortedOffers = sortedOffers.filter((offer) => offer.whiteList);
			setSortedOffers(sortedOffers);
		}
		// ------------------------------------------------------------
		// -- Calculate and filter 'offers' for WalletPourchasesOffersHead --
		// ------------------------------------------------------------
		// console.log("offers", offers[0]);
		{
			let sortedWalletOffersCount = 0;
			let sortedWalletOffers = JSON.parse(JSON.stringify(tokens));

			sortedWalletOffers = sortedWalletOffers.filter((token) => token.whiteList);
			{
				sortedWalletOffers.forEach((token) => {
					token.position = token.walletsAmount;
					token.positionWallet = token.walletAmount ? token.walletAmount : 0;
					token.rentStartDate = token.rentStartDate.date.slice(0, 10);
					token.token_rented = token.rentedUnits / token.totalUnits;
					token.offersList = offers.filter((offer) => offer.holder_address === settingsWallet.selectedWallet && offer.shortName === token.shortName);
					const selectedWallet = settingsWallet.selectedWallet;

					token.offersList.forEach((offer) => {
						offer.editOffer = newOrModifyOffers.find(
							(newOffer) =>
								offer.holder_address === selectedWallet &&
								newOffer.holder_address === selectedWallet &&
								offer.shortName === newOffer.shortName &&
								offer.id_offer === newOffer.id_offer
						);
					});

					token.newOffersList = newOrModifyOffers.filter(
						(offer) => offer.holder_address === settingsWallet.selectedWallet && offer.shortName === token.shortName && offer.newOffer
					);

					token.handleCheckedOffers = handleCheckedOffers; // Référence directe à la fonction
					token.handleAddOffers = handleAddOffers; // Référence directe à la fonction
					token.handleEditOffers = handleEditOffers; // Référence directe à la fonction
					token.handleRemoveOffers = handleRemoveOffers; // Référence directe à la fonction

					// token.nbOffers = token.offersList.length;
					// sortedWalletOffersCount += token.offersList.length;
				});

				// Filtrage des offres en fonctions des 3 boutons de filtrage
				if (settingsMarket.WalletPurchaseOffers.unselectedCity)
					sortedWalletOffers = sortedWalletOffers.filter((token) => {
						const city = token.fullName.split(",")[1].trim();
						return !settingsMarket.WalletPurchaseOffers.unselectedCity.includes(city);
					});
				if (settingsMarket.WalletPurchaseOffers.unselectedCoins) {
					sortedWalletOffers = sortedWalletOffers.map((token) => {
						return {
							...token,
							offersList: token.offersList.filter((offer) => {
								// Vérifie si le token_to_pay_name de l'offre est dans la liste unselectedCoins
								return !settingsMarket.WalletPurchaseOffers.unselectedCoins.includes(offer.token_to_pay_name);
							}),
						};
					});
				}
				if (settingsMarket.WalletPurchaseOffers.unselectPropertyType)
					sortedWalletOffers = sortedWalletOffers.filter((token) => {
						const propertyType = token.propertyType;
						return !settingsMarket.WalletPurchaseOffers.unselectPropertyType.includes(propertyType);
					});

				// Filtrage des offres en fonctions des reglages
				if (settingsMarket.WalletPurchaseOffers.isPosition)
					sortedWalletOffers = sortedWalletOffers.filter(
						(token) =>
							token.position >= settingsMarket.WalletPurchaseOffers.minPosition && token.position < settingsMarket.WalletPurchaseOffers.maxPosition
					);
				if (settingsMarket.WalletPurchaseOffers.isRealtPrice)
					sortedWalletOffers = sortedWalletOffers.filter(
						(token) =>
							token.tokenPrice >= settingsMarket.WalletPurchaseOffers.minRealtPrice &&
							token.tokenPrice < settingsMarket.WalletPurchaseOffers.maxRealtPrice
					);
				if (settingsMarket.WalletPurchaseOffers.isRealtYield)
					sortedWalletOffers = sortedWalletOffers.filter(
						(token) =>
							token.annualPercentageYield >= settingsMarket.WalletPurchaseOffers.minRealtYield &&
							token.annualPercentageYield < settingsMarket.WalletPurchaseOffers.maxRealtYield
					);
				// console.log(sortedWalletOffers[0].token_rented,settingsMarket.WalletPurchaseOffers.minRented,settingsMarket.WalletPurchaseOffers.maxRented)
				if (settingsMarket.WalletPurchaseOffers.isRented)
					sortedWalletOffers = sortedWalletOffers.filter(
						(token) =>
							token.token_rented * 100 >= settingsMarket.WalletPurchaseOffers.minRented &&
							token.token_rented * 100 < settingsMarket.WalletPurchaseOffers.maxRented
					);
				if (settingsMarket.WalletPurchaseOffers.isRentStart)
					sortedWalletOffers = sortedWalletOffers.filter((token) => new Date(token.rentStartDate).getTime() <= new Date().getTime());
				if (settingsMarket.WalletPurchaseOffers.isnotRentStart)
					sortedWalletOffers = sortedWalletOffers.filter((token) => new Date(token.rentStartDate).getTime() >= new Date().getTime());

				sortedWalletOffers.forEach((token) => {
					token.nbOffers = token.offersList.length;
					sortedWalletOffersCount += token.offersList.length;
				});

				{
					// if (settingsMarket.PurchaseOffers.isOfferAmount)
					// 	sortedOffers = sortedOffers.filter(
					// 		(offer) =>
					// 			offer.token_amount >= settingsMarket.PurchaseOffers.minOfferAmount && offer.token_amount < settingsMarket.PurchaseOffers.maxOfferAmount
					// 	);
					// if (settingsMarket.PurchaseOffers.isRatioPrice)
					// 	sortedOffers = sortedOffers.filter(
					// 		(offer) =>
					// 			offer.token_ratio * 100 >= settingsMarket.PurchaseOffers.minRatioPrice &&
					// 			offer.token_ratio * 100 < settingsMarket.PurchaseOffers.maxRatioPrice
					// 	);
					// if (settingsMarket.PurchaseOffers.isNewPrice)
					// 	sortedOffers = sortedOffers.filter(
					// 		(offer) =>
					// 			offer.token_value_usd >= settingsMarket.PurchaseOffers.minNewPrice && offer.token_value_usd < settingsMarket.PurchaseOffers.maxNewPrice
					// 	);
					// if (settingsMarket.PurchaseOffers.isNewYield)
					// 	sortedOffers = sortedOffers.filter(
					// 		(offer) =>
					// 			offer.token_newYield * 100 >= settingsMarket.PurchaseOffers.minNewYield &&
					// 			offer.token_newYield * 100 < settingsMarket.PurchaseOffers.maxNewYield
					// 	);
					// if (settingsMarket.PurchaseOffers.isLastPricingDays)
					// 	sortedOffers = sortedOffers.filter(
					// 		(offer) =>
					// 			offer.last_pricing_days >= settingsMarket.PurchaseOffers.minLastPricingDays &&
					// 			offer.last_pricing_days < settingsMarket.PurchaseOffers.maxLastPricingDays
					// 	);
					// if (settingsMarket.PurchaseOffers.isNbPricing)
					// 	sortedOffers = sortedOffers.filter(
					// 		(offer) => offer.nb_pricing >= settingsMarket.PurchaseOffers.minNbPricing && offer.nb_pricing < settingsMarket.PurchaseOffers.maxNbPricing
					// 	);
					// if (settingsMarket.PurchaseOffers.isWhiteList) sortedOffers = sortedOffers.filter((offer) => offer.whiteList);
				}
			}
			setSortedWalletOffersCount(sortedWalletOffersCount);
			setSortedWalletOffers(sortedWalletOffers);
		}
	}, [tokens, newOrModifyOffers, settingsMarket.SwapCatOffersList, settingsMarket.YamOffersList]);

	// useEffect(() => {
		// console.log("newOrModifyOffers", newOrModifyOffers);
	// }, [sortedWalletOffers]);

	// Fonctions de gestion des événements
	const handleCheckedOffers = (id_Offer, checked) => {
		console.log("handleCheckedOffer", id_Offer, checked);
	};

	const handleAddOffers = (id, shortName, newOffer) => {
		// console.log("handleAddOffer", id, shortName, newOffer);

		const token = tokens.filter((object) => object.shortName === shortName);
		if (token.length > 0)
			token.forEach((object) => {
				if (!object.gnosisContract === newOffer.tokenToBuy) {
					newOffer.tokenToBuy = object.gnosisContract;
					newOffer.shortName = object.shortName;
				}
			});

		if (id === 0) {
			// Ajouter une nouvelle offre
			setNewOrModifyOffers((prevOffers) => [
				...prevOffers,
				{ id: idMax, shortName: shortName, newOffer: newOffer, holder_address: settingsWallet.selectedWallet },
			]);
			setIdMax((prevIdMax) => prevIdMax + 1); // Utilise la forme fonctionnelle pour idMax aussi
		} else {
			// Modifier une offre existante
			setNewOrModifyOffers((prevOffers) => {
				// const activeOffers = prevOffers.filter((offer) => offer?.newOffer.isDeleted === false).filter((offer) => offer?.initOffer.isDeleted === false);
				const updatedOffers = prevOffers.filter((offer) => offer.id !== id);
				return [...updatedOffers, { id, shortName, newOffer, holder_address: settingsWallet.selectedWallet }];
			});
			setIdMax((prevIdMax) => prevIdMax + 1); // Met à jour idMax de manière fonctionnelle
		}
	};

	const handleRemoveOffers = (id) => {
		console.log("handleRemoveOffer", id);
		setNewOrModifyOffers((prevOffers) => {
			const updatedOffers = prevOffers.filter((offer) => offer.id !== id);
			return updatedOffers;
		});
	};

	const handleEditOffers = (id, id_offer, shortName, editOffer) => {
		console.log("handleEditOffers", id, shortName, editOffer);

		if (id === 0) {
			// Ajouter une nouvelle editOffre
			setNewOrModifyOffers((prevOffers) => [
				...prevOffers,
				{ id: idMax, id_offer: id_offer, shortName: shortName, editOffer: editOffer, holder_address: settingsWallet.selectedWallet },
			]);
			setIdMax((prevIdMax) => prevIdMax + 1); // Met à jour idMax de manière fonctionnelle
		} else {
			// Modifier une offre existante
			setNewOrModifyOffers((prevOffers) => {
				const updatedOffers = prevOffers.filter((offer) => offer.id !== id);
				return [...updatedOffers, { id, id_offer, shortName, editOffer, holder_address: settingsWallet.selectedWallet }];
			});
			setIdMax((prevIdMax) => prevIdMax + 1); // Met à jour idMax de manière fonctionnelle
		}
	};

	useEffect(() => {
		// Fonction utilitaire pour récupérer le nom de l'état
		const getStateName = (stateCode) => {
			const states = {
				AL: "Alabama",
				AK: "Alaska",
				AZ: "Arizona",
				AR: "Arkansas",
				CA: "California",
				CO: "Colorado",
				CT: "Connecticut",
				DE: "Delaware",
				FL: "Florida",
				GA: "Georgia",
				HI: "Hawaii",
				ID: "Idaho",
				IL: "Illinois",
				IN: "Indiana",
				IA: "Iowa",
				KS: "Kansas",
				KY: "Kentucky",
				LA: "Louisiana",
				ME: "Maine",
				MD: "Maryland",
				MA: "Massachusetts",
				MI: "Michigan",
				MN: "Minnesota",
				MS: "Mississippi",
				MO: "Missouri",
				MT: "Montana",
				NE: "Nebraska",
				NV: "Nevada",
				NH: "New Hampshire",
				NJ: "New Jersey",
				NM: "New Mexico",
				NY: "New York",
				NC: "North Carolina",
				ND: "North Dakota",
				OH: "Ohio",
				OK: "Oklahoma",
				OR: "Oregon",
				PA: "Pennsylvania",
				RI: "Rhode Island",
				SC: "South Carolina",
				SD: "South Dakota",
				TN: "Tennessee",
				TX: "Texas",
				UT: "Utah",
				VT: "Vermont",
				VA: "Virginia",
				WA: "Washington",
				WV: "West Virginia",
				WI: "Wisconsin",
				WY: "Wyoming",
			};
			return states[stateCode.toUpperCase()] || "Unknown State";
		};

		if (!tokens) return;
		const _cityList = tokens
			.reduce((acc, token) => {
				const parts = token.fullName.split(",").map((part) => part.trim());
				let city, countryLabel;

				if (token.fullName.split(",").length === 3) {
					if (isCity) city = token.fullName.split(",")[1].trim();
					else city = getStateName(token.fullName.split(",")[2].trim().slice(0, 2));
				} else {
					if (isCity) city = token.fullName.split(",")[1].trim();
					else city = token.fullName.split(",")[3].trim();
				}
				// ? getStateName(token.token.fullName.split(",")[2].trim().slice(0, 2))
				// : token.token.fullName.split(",")[3].trim();

				let cityEntry = acc ? acc.find((entry) => entry.label === city) : null;
				if (!cityEntry) {
					cityEntry = { id: acc.length, count: 0, label: city, checked: true };
					acc.push(cityEntry);
				}
				cityEntry.count += 1;
				return acc;
			}, [])
			.sort((a, b) => {
				return a.label.localeCompare(b.label);
			});
		setCityList(_cityList);
		// console.log("cityList Calculate");
		// console.table(_cityList);
	}, [tokens]);

	useEffect(() => {
		if (!offers) return;

		const generateCoinsList = (offers, selectedWallet = null) => {
			return offers
				.reduce((acc, offer) => {
					const shouldInclude = !selectedWallet || offer.holder_address === selectedWallet;
					if (!shouldInclude) return acc;

					let coinEntry = acc.find((entry) => entry.id === offer.token_to_pay);
					if (!coinEntry) {
						coinEntry = {
							id: offer.token_to_pay,
							count: 0,
							label: offer.token_to_pay_name,
							img: offer.token_to_pay_img,
							checked: true,
						};
						acc.push(coinEntry);
					}
					coinEntry.count += 1;
					return acc;
				}, [])
				.sort((a, b) => b.count - a.count);
		};

		// Générer _coinsList et _walletCoinsList avec la fonction optimisée
		const _coinsList = generateCoinsList(offers);
		const _walletCoinsList = generateCoinsList(offers, settingsWallet?.selectedWallet);

		setCoinsList(_coinsList);
		setWalletCoinsList(_walletCoinsList);
		// console.log("coinsList Calculate");
		// console.table(_coinsList);
		// console.table(_walletCoinsList);
	}, [offers]);

	if (!realTokens)
		return (
			<Paper key='balanceWallets' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!holderWallets)
		return (
			<Paper key='holderWallets' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: red[700], borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					<CircularProgress sx={{ m: 2, color: red[700] }} size='2rem' />
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: red[700] }}>
						No wallet define, go to Settings !
					</Typography>
				</Box>
			</Paper>
		);
	if (!holderWallets.length > 0)
		return (
			<Paper key='holderWallets' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: red[700], borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					<CircularProgress sx={{ m: 2, color: red[700] }} size='2rem' />
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: red[700] }}>
						No wallet define, go to Settings !
					</Typography>
				</Box>
			</Paper>
		);
	if (!balanceWallets)
		return (
			<Paper key='balanceWallets' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!tokens)
		return (
			<Paper key='tokens' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!coinsList)
		return (
			<>
				<Paper key='coinsList' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
					<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
						{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
						<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
							Waiting Offers synchronisation ... Or no wallet define !
						</Typography>
					</Box>
				</Paper>
			</>
		);
	if (!cityList)
		return (
			<Paper key='cityList' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!settingsMarket.PurchaseOffers)
		return (
			<Paper key='settingsMarket' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!sortedOffers)
		return (
			<Paper key='sortedOffers' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!sortedWalletOffers)
		return (
			<Paper key='sortedOffers' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!settingsMarket.SwapCatOffersList)
		return (
			<Paper key='settingsMarket.SwapCatOffersList' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!settingsMarket.YamOffersList)
		return (
			<Paper key='settingsMarket.YamOffersList' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				height: "100%",
			}}
		>
			<Box
				sx={{
					width: "auto",
					// flexGrow: 0.1,
					border: 0,
					borderColor: "primary.main",
					borderRadius: 2,
					display: "flex",
					flexDirection: "column",
					justifyContent: "start",
					alignItems: "center",
				}}
			>
				<StorefrontIcon
					color={allOffers ? "primary" : "disabled"}
					sx={{ ml: 1, mt: 0, fontSize: allOffers ? 40 : 25, cursor: allOffers ? "default" : "pointer" }}
					onClick={() => {
						setAllOffers(true);
						setWalletOffers(false);
						setHelpOffers(false);
						setShopping(false);
					}}
				/>

				<Badge
					sx={{ ml: 1, mt: 2, fontSize: walletOffers ? 40 : 25, cursor: shopping ? "default" : "pointer" }}
					badgeContent={sortedWalletOffersCount}
					color='success'
					onClick={() => {
						setAllOffers(false);
						setWalletOffers(true);
						setHelpOffers(false);
						setShopping(false);
					}}
				>
					<AccountBalanceWalletOutlinedIcon
						color={walletOffers ? "primary" : "disabled"}
						sx={{ ml: 0, mt: 0, fontSize: walletOffers ? 40 : 25, cursor: walletOffers ? "default" : "pointer" }}
						onClick={() => {
							setAllOffers(false);
							setWalletOffers(true);
							setHelpOffers(false);
							setShopping(false);
						}}
					/>
				</Badge>

				<InfoOutlinedIcon
					color={helpOffers ? "primary" : "disabled"}
					sx={{ ml: 1, mt: 2, fontSize: helpOffers ? 40 : 25, cursor: helpOffers ? "default" : "pointer" }}
					onClick={() => {
						setAllOffers(false);
						setWalletOffers(false);
						setHelpOffers(true);
						setShopping(false);
					}}
				/>

				<Badge
					sx={{ ml: 1, mt: 5, cursor: shopping ? "default" : "pointer" }}
					badgeContent={0}
					color='success'
					onClick={() => {
						setAllOffers(false);
						setWalletOffers(false);
						setHelpOffers(false);
						setShopping(true);
					}}
				>
					<ShoppingCartIcon
						color={shopping ? "primary" : "disabled"}
						sx={{ fontSize: shopping ? 40 : 25, cursor: shopping ? "default" : "pointer" }}
						onClick={() => {
							setAllOffers(false);
							setWalletOffers(false);
							setHelpOffers(false);
							setShopping(true);
						}}
					/>
				</Badge>
			</Box>

			{allOffers && (
				<Box
					sx={{
						flexGrow: 1,
						mx: 1,
						pr: isMatch ? 2 : 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 2,
					}}
				>
					<PurchasesOffersHead key='PurchasesOffersHead' offers={sortedOffers} cityList={cityList} coinsList={coinsList} />
				</Box>
			)}

			{walletOffers && (
				<Box
					sx={{
						flexGrow: 1,
						mx: 1,
						pr: isMatch ? 2 : 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 2,
					}}
				>
					<WalletPurchasesOffersHead
						key='WalletPurchasesOffersHead'
						offers={sortedWalletOffers}
						cityList={cityList}
						coinsList={walletCoinsList}
						newOrModifyOffers={newOrModifyOffers}
						setNewOrModifyOffers={setNewOrModifyOffers}
					/>
				</Box>
			)}

			{helpOffers && (
				<Box
					sx={{
						flexGrow: 1,
						mx: 1,
						pr: isMatch ? 2 : 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 2,
					}}
				>
					helpOffers SOON !!!
					<br />
					{/* <TokensTableHead tokens={tokens} /> */}
				</Box>
			)}

			{shopping && (
				<Box
					sx={{
						flexGrow: 1,
						mx: 1,
						pr: isMatch ? 2 : 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 2,
					}}
				>
					Shopping, It's an idea for future ... <br />
					{/* <TokensTableHead tokens={tokens} /> */}
				</Box>
			)}
		</Box>
	);
}

export default PurchasesOffers;
