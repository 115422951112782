// src/context/web3-context.jsx
import React, { createContext, useState, useEffect, useContext } from "react";
import Web3 from "web3";

import { SettingsContext } from "./settings-context";
import { SnackbarContext } from "./customsnackbar-context";

export const Web3Context = createContext();

const intToHex = (int) => {
	return "0x" + int.toString(16).padStart(2, "0");
};
const hexToInt = (hex) => {
	return parseInt(hex, 16);
};

export const Web3Provider = ({ children }) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { blockchainsClient, holderWallets, settingsWallet, setSettingsWallet } = useContext(SettingsContext);
	const [web3, setWeb3] = useState(null);
	const [web3active, setWeb3Active] = useState(false);
	const [web3account, setAccount] = useState(null);
	const [web3accountName, setAccountName] = useState(null);

	let isConnecting = false;

	const yamAddress = "0xC759AA7f9dd9720A1502c104DaE4F9852bb17C14".toLowerCase();
	const swapcatAddress = "0xB18713Ac02Fc2090c0447e539524a5c76f327a3b".toLowerCase();
	const yamABI = [
		{ inputs: [], stateMutability: "nonpayable", type: "constructor" },
		{
			anonymous: false,
			inputs: [
				{ indexed: false, internalType: "address", name: "previousAdmin", type: "address" },
				{ indexed: false, internalType: "address", name: "newAdmin", type: "address" },
			],
			name: "AdminChanged",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [{ indexed: true, internalType: "address", name: "beacon", type: "address" }],
			name: "BeaconUpgraded",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "uint256", name: "oldFee", type: "uint256" },
				{ indexed: true, internalType: "uint256", name: "newFee", type: "uint256" },
			],
			name: "FeeChanged",
			type: "event",
		},
		{ anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" },
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "uint256", name: "offerId", type: "uint256" },
				{ indexed: true, internalType: "address", name: "seller", type: "address" },
				{ indexed: true, internalType: "address", name: "buyer", type: "address" },
				{ indexed: false, internalType: "address", name: "offerToken", type: "address" },
				{ indexed: false, internalType: "address", name: "buyerToken", type: "address" },
				{ indexed: false, internalType: "uint256", name: "price", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "OfferAccepted",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "address", name: "offerToken", type: "address" },
				{ indexed: true, internalType: "address", name: "buyerToken", type: "address" },
				{ indexed: false, internalType: "address", name: "seller", type: "address" },
				{ indexed: false, internalType: "address", name: "buyer", type: "address" },
				{ indexed: true, internalType: "uint256", name: "offerId", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "price", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "OfferCreated",
			type: "event",
		},
		{ anonymous: false, inputs: [{ indexed: true, internalType: "uint256", name: "offerId", type: "uint256" }], name: "OfferDeleted", type: "event" },
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "uint256", name: "offerId", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "oldPrice", type: "uint256" },
				{ indexed: true, internalType: "uint256", name: "newPrice", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "oldAmount", type: "uint256" },
				{ indexed: true, internalType: "uint256", name: "newAmount", type: "uint256" },
			],
			name: "OfferUpdated",
			type: "event",
		},
		{ anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }], name: "Paused", type: "event" },
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
				{ indexed: true, internalType: "bytes32", name: "previousAdminRole", type: "bytes32" },
				{ indexed: true, internalType: "bytes32", name: "newAdminRole", type: "bytes32" },
			],
			name: "RoleAdminChanged",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
				{ indexed: true, internalType: "address", name: "account", type: "address" },
				{ indexed: true, internalType: "address", name: "sender", type: "address" },
			],
			name: "RoleGranted",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
				{ indexed: true, internalType: "address", name: "account", type: "address" },
				{ indexed: true, internalType: "address", name: "sender", type: "address" },
			],
			name: "RoleRevoked",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "address[]", name: "tokens", type: "address[]" },
				{ indexed: true, internalType: "enum IRealTokenYamUpgradeableV3.TokenType[]", name: "types", type: "uint8[]" },
			],
			name: "TokenWhitelistWithTypeToggled",
			type: "event",
		},
		{ anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }], name: "Unpaused", type: "event" },
		{
			anonymous: false,
			inputs: [{ indexed: true, internalType: "address", name: "implementation", type: "address" }],
			name: "Upgraded",
			type: "event",
		},
		{
			inputs: [],
			name: "DEFAULT_ADMIN_ROLE",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "MODERATOR_ROLE",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "UPGRADER_ROLE",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "buy",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256[]", name: "_offerIds", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_prices", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
			],
			name: "buyOfferBatch",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
				{ internalType: "uint256", name: "deadline", type: "uint256" },
				{ internalType: "uint8", name: "v", type: "uint8" },
				{ internalType: "bytes32", name: "r", type: "bytes32" },
				{ internalType: "bytes32", name: "s", type: "bytes32" },
			],
			name: "buyWithPermit",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "offerToken", type: "address" },
				{ internalType: "address", name: "buyerToken", type: "address" },
				{ internalType: "address", name: "buyer", type: "address" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "createOffer",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address[]", name: "_offerTokens", type: "address[]" },
				{ internalType: "address[]", name: "_buyerTokens", type: "address[]" },
				{ internalType: "address[]", name: "_buyers", type: "address[]" },
				{ internalType: "uint256[]", name: "_prices", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
			],
			name: "createOfferBatch",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "offerToken", type: "address" },
				{ internalType: "address", name: "buyerToken", type: "address" },
				{ internalType: "address", name: "buyer", type: "address" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
				{ internalType: "uint256", name: "newAllowance", type: "uint256" },
				{ internalType: "uint256", name: "deadline", type: "uint256" },
				{ internalType: "uint8", name: "v", type: "uint8" },
				{ internalType: "bytes32", name: "r", type: "bytes32" },
				{ internalType: "bytes32", name: "s", type: "bytes32" },
			],
			name: "createOfferWithPermit",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256", name: "offerId", type: "uint256" }],
			name: "deleteOffer",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256[]", name: "offerIds", type: "uint256[]" }],
			name: "deleteOfferBatch",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256[]", name: "offerIds", type: "uint256[]" }],
			name: "deleteOfferByAdmin",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{ inputs: [], name: "fee", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" },
		{
			inputs: [{ internalType: "uint256", name: "offerId", type: "uint256" }],
			name: "getInitialOffer",
			outputs: [
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "uint256", name: "", type: "uint256" },
				{ internalType: "uint256", name: "", type: "uint256" },
			],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "getOfferCount",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
			name: "getRoleAdmin",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [{ internalType: "address", name: "token", type: "address" }],
			name: "getTokenType",
			outputs: [{ internalType: "enum IRealTokenYamUpgradeableV3.TokenType", name: "", type: "uint8" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "bytes32", name: "role", type: "bytes32" },
				{ internalType: "address", name: "account", type: "address" },
			],
			name: "grantRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "bytes32", name: "role", type: "bytes32" },
				{ internalType: "address", name: "account", type: "address" },
			],
			name: "hasRole",
			outputs: [{ internalType: "bool", name: "", type: "bool" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "admin_", type: "address" },
				{ internalType: "address", name: "moderator_", type: "address" },
			],
			name: "initialize",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{ inputs: [], name: "pause", outputs: [], stateMutability: "nonpayable", type: "function" },
		{ inputs: [], name: "paused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" },
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "pricePreview",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "proxiableUUID",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "bytes32", name: "role", type: "bytes32" },
				{ internalType: "address", name: "account", type: "address" },
			],
			name: "renounceRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "bytes32", name: "role", type: "bytes32" },
				{ internalType: "address", name: "account", type: "address" },
			],
			name: "revokeRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "address", name: "token", type: "address" }],
			name: "saveLostTokens",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256", name: "fee_", type: "uint256" }],
			name: "setFee",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256", name: "offerId", type: "uint256" }],
			name: "showOffer",
			outputs: [
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "uint256", name: "", type: "uint256" },
				{ internalType: "uint256", name: "", type: "uint256" },
			],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
			name: "supportsInterface",
			outputs: [{ internalType: "bool", name: "", type: "bool" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address[]", name: "tokens_", type: "address[]" },
				{ internalType: "enum IRealTokenYamUpgradeableV3.TokenType[]", name: "types_", type: "uint8[]" },
			],
			name: "toggleWhitelistWithType",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "address", name: "tokenAddr", type: "address" }],
			name: "tokenInfo",
			outputs: [
				{ internalType: "uint256", name: "", type: "uint256" },
				{ internalType: "string", name: "", type: "string" },
				{ internalType: "string", name: "", type: "string" },
			],
			stateMutability: "view",
			type: "function",
		},
		{ inputs: [], name: "unpause", outputs: [], stateMutability: "nonpayable", type: "function" },
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "updateOffer",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256[]", name: "_offerIds", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_prices", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
			],
			name: "updateOfferBatch",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
				{ internalType: "uint256", name: "newAllowance", type: "uint256" },
				{ internalType: "uint256", name: "deadline", type: "uint256" },
				{ internalType: "uint8", name: "v", type: "uint8" },
				{ internalType: "bytes32", name: "r", type: "bytes32" },
				{ internalType: "bytes32", name: "s", type: "bytes32" },
			],
			name: "updateOfferWithPermit",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "address", name: "newImplementation", type: "address" }],
			name: "upgradeTo",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "newImplementation", type: "address" },
				{ internalType: "bytes", name: "data", type: "bytes" },
			],
			name: "upgradeToAndCall",
			outputs: [],
			stateMutability: "payable",
			type: "function",
		},
	];
	const swapcatABI = [
		{
			constant: true,
			inputs: [{ name: "_tokenaddr", type: "address" }],
			name: "tokeninfo",
			outputs: [
				{ name: "", type: "uint256" },
				{ name: "", type: "string" },
				{ name: "", type: "string" },
			],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		{
			constant: false,
			inputs: [{ name: "token", type: "address" }],
			name: "losttokens",
			outputs: [],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			constant: false,
			inputs: [{ name: "_offerid", type: "uint24" }],
			name: "deleteoffer",
			outputs: [{ name: "", type: "string" }],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			constant: false,
			inputs: [
				{ name: "_offertoken", type: "address" },
				{ name: "_buyertoken", type: "address" },
				{ name: "_price", type: "uint256" },
				{ name: "_offerid", type: "uint24" },
			],
			name: "makeoffer",
			outputs: [{ name: "", type: "uint24" }],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			constant: true,
			inputs: [{ name: "_offerid", type: "uint24" }],
			name: "showoffer",
			outputs: [
				{ name: "", type: "address" },
				{ name: "", type: "address" },
				{ name: "", type: "address" },
				{ name: "", type: "uint256" },
				{ name: "", type: "uint256" },
			],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		{
			constant: true,
			inputs: [
				{ name: "_offerid", type: "uint24" },
				{ name: "_amount", type: "uint256" },
			],
			name: "pricepreview",
			outputs: [{ name: "", type: "uint256" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		{
			constant: false,
			inputs: [
				{ name: "_offerid", type: "uint24" },
				{ name: "_offertokenamount", type: "uint256" },
				{ name: "_price", type: "uint256" },
			],
			name: "buy",
			outputs: [{ name: "", type: "string" }],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			constant: true,
			inputs: [],
			name: "getoffercount",
			outputs: [{ name: "", type: "uint24" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		{ payable: true, stateMutability: "payable", type: "fallback" },
	];
	const ERC20ABI = [
		// BalanceOf function
		{
			constant: true,
			inputs: [{ name: "_owner", type: "address" }],
			name: "balanceOf",
			outputs: [{ name: "balance", type: "uint256" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		// Allowance function
		{
			constant: true,
			inputs: [
				{ name: "_owner", type: "address" },
				{ name: "_spender", type: "address" },
			],
			name: "allowance",
			outputs: [{ name: "remaining", type: "uint256" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		// Decimals function
		{
			constant: true,
			inputs: [],
			name: "decimals",
			outputs: [{ name: "", type: "uint8" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		// Approve function
		{
			constant: false,
			inputs: [
				{ name: "_spender", type: "address" },
				{ name: "_value", type: "uint256" },
			],
			name: "approve",
			outputs: [{ name: "", type: "bool" }],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
	];

	const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

	const connect = async () => {
		if (isConnecting) return;
		// console.log("connect", window.ethereum);
		if (window.ethereum) {
			try {
				isConnecting = true;
				const web3Instance = new Web3(window.ethereum);

				// Demande l'accès au compte
				await window.ethereum.enable();
				const accounts = await web3Instance.eth.getAccounts();
				// const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

				if (accounts.length === 0) {
					console.error("No accounts found");
					setWeb3Active(false);
					isConnecting = false;
					return false;
				} else {
					console.log("accounts", accounts);
				}

				setWeb3(web3Instance);
				setAccount(accounts[0] ? accounts[0].toLowerCase() : null);
				setWeb3Active(true);

				// Switch network
				await selectNetwork();

				const holderWallet = holderWallets.filter((w) => w.address === accounts[0].toLowerCase());

				if (holderWallet.length > 0) {
					const name = holderWallet[0].name;
					const address = `${holderWallet[0].address.slice(0, 6)}...${holderWallet[0].address.slice(-4)}`;
					openSnackbar(`Web3 connected with account: ${name ? name : address}`, "success");
					console.log("Web3 connected with account:", name ? name : address);
					setAccountName(name ? name : `${address.slice(0, 6)}...${address.slice(-4)}`);

					if (settingsWallet.selectedWallet !== holderWallet[0].address)
						setSettingsWallet((prevSettings) => ({
							...prevSettings,
							selectedWallet: holderWallet[0].address,
						}));
				}
				isConnecting = false;
				return true;
			} catch (error) {
				if (error.code === -32001) {
					console.log("User rejected the connection request. Retrying in 1000ms...");
					// if (!web3active)
					// 	setTimeout(() => {
					// 		connect();
					// 	}, 1000);
				} else {
					openSnackbar(`Error during connect: ${error.message}`, "error");
					console.error("Error during connect:", error);
					setWeb3Active(false);
				}
			} finally {
				isConnecting = false;
			}
		} else {
			console.log("Non-Ethereum browser detected. You should consider trying MetaMask!");
			setWeb3Active(false);
		}
		isConnecting = false;
		return false;
	};

	const disconnect = () => {
		setWeb3(null);
		setAccount(null);
		setAccountName(null);
		setWeb3Active(false);
		console.log("Web3 disconnected");
	};

	const changeNetwork = async (chainId) => {
		if (window.ethereum) {
			if (settingsWallet && blockchainsClient) {
				const blockchainsArray = Object.entries(blockchainsClient).map(([id, data]) => ({
					...data,
				}));
				const blockchainClient = blockchainsArray.filter((b) => b.chainId === hexToInt(chainId));
				if (blockchainClient.length > 0) {
					setSettingsWallet((prevSettings) => ({
						...prevSettings,
						selectedBlockchain: blockchainClient[0].name,
					}));
				} else {
					setSettingsWallet((prevSettings) => ({
						...prevSettings,
						selectedBlockchain: "",
					}));
				}
			}
		}
	};

	const selectNetwork = async () => {
		if (window.ethereum) {
			if (settingsWallet && blockchainsClient) {
				if (settingsWallet.selectedBlockchain) {
					try {
						const chainId = intToHex(blockchainsClient[settingsWallet.selectedBlockchain].chainId);
						await window.ethereum.request({
							method: "wallet_switchEthereumChain",
							params: [{ chainId }],
						});
					} catch (error) {
						if (error.code === 4902) {
							console.log("Le réseau n'est pas ajouté dans MetaMask.");
						} else {
							console.error("Erreur lors du changement de réseau", error);
						}
					}
				}
			}
		}
	};

	const getBalance = async () => {
		if (web3 && web3account) {
			try {
				const balance = await web3.eth.getBalance(web3account);
				return balance;
			} catch (error) {
				console.error("Error while fetching the balance:", error);
				return null;
			}
		}
		return null;
	};

	const getTokenBalance = async (tokenAddress) => {
		if (web3 && web3account) {
			try {
				const tokenContract = new web3.eth.Contract(ERC20ABI, tokenAddress);
				const balance = await tokenContract.methods.balanceOf(web3account).call();
				const decimals = await tokenContract.methods.decimals().call();
				const formattedBalance = balance / 10 ** decimals;
				return formattedBalance;
			} catch (error) {
				console.error("Error while fetching the balance:", error);
				return null;
			}
		}
		return null;
	};

	const tokenApprove = async (tokenToApprove, newAllowance, spenderAddress, callback) => {
		// console.log(tokenToApprove, newAllowance, spenderAddress, callback);

		let connected = web3active;
		if (!web3active) {
			connected = await connect();
			// await delay(3000);
		}

		if (connected && web3) {
			try {
				const smartContract = new web3.eth.Contract(ERC20ABI, tokenToApprove);
				const digit = Number(await smartContract.methods.decimals().call());
				const allowance = Math.round(newAllowance * 10 ** digit * 1.001);

				// Appel de la méthode approve avec une nouvelle allowance valide
				const result = await smartContract.methods.approve(spenderAddress, allowance).send({ from: web3account });
				callback(result);
				return;
			} catch (error) {
				console.error("Error during tokenApprove:", error);
				openSnackbar(`Error during tokenApprove: ${error}`, "error");
			}
		}
		callback(null);
	};

	const tokenBuySwapCat = async (offer, liveOffer, tokenAmount, callback) => {
		// console.log(offer, liveOffer, tokenAmount);

		let connected = web3active;
		if (!web3active) {
			connected = await connect();
			// await delay(3000);
		}

		if (connected && web3) {
			try {
				// _offerid (uint24)
				// _offertokenamount (uint256)
				// _price (uint256)
				const smartContract_token_to_sell = new web3.eth.Contract(ERC20ABI, offer.token_to_sell);
				// const smartContract_token_to_pay = new web3.eth.Contract(ERC20ABI, offer.token_to_pay);
				const smartContract = new web3.eth.Contract(swapcatABI, swapcatAddress);
				const digit_to_sell = Number(await smartContract_token_to_sell.methods.decimals().call());
				// const digit_to_pay = Number(await smartContract_token_to_pay.methods.decimals().call());

				const _offerid = offer.id_offer;
				const _offertokenamount = Math.round(tokenAmount * 10 ** digit_to_sell);
				const _price = liveOffer.priceInOffer;

				console.log("tokenBuySwapCat", _offerid, "_price", _price, "_offertokenamount", _offertokenamount);

				const result = await smartContract.methods.buy(_offerid, _offertokenamount, _price).send({ from: web3account });
				openSnackbar(`New realToken buy successfully: ${offer.token.shortName}`, "success");
				callback(result);
				return null;
			} catch (error) {
				console.error("Error during tokenBuySwapCat:", error);
				openSnackbar(`Error during tokenBuySwapCat: ${error}`, "error");
			}
		}
		callback(null);
		return null;
	};

	const tokenBuyYam = async (offer, liveOffer, tokenAmount, callback) => {
		// console.log(offer, liveOffer, tokenAmount);

		let connected = web3active;
		if (!web3active) {
			connected = await connect();
			// await delay(3000);
		}

		if (connected && web3) {
			try {
				// _offerid (uint24)
				// _price (uint256)
				// _offertokenamount (uint256)
				const smartContract_token_to_sell = new web3.eth.Contract(ERC20ABI, offer.token_to_sell);
				// const smartContract_token_to_pay = new web3.eth.Contract(ERC20ABI, offer.token_to_pay);
				const smartContract = new web3.eth.Contract(yamABI, yamAddress);
				const digit_to_sell = Number(await smartContract_token_to_sell.methods.decimals().call());
				// const digit_to_pay = Number(await smartContract_token_to_pay.methods.decimals().call());

				const _offerid = offer.id_offer;
				const _price = liveOffer.priceInOffer;
				const _offertokenamount = Math.round(tokenAmount * 10 ** digit_to_sell);

				console.log("tokenBuyYam", _offerid, "_price", _price, "_offertokenamount", _offertokenamount);

				const result = await smartContract.methods.buy(_offerid, _price, _offertokenamount).send({ from: web3account });
				openSnackbar(`New realToken buy successfully: ${offer.token.shortName}`, "success");
				callback(result);
				return null;
			} catch (error) {
				console.error("Error during tokenBuyYam:", error);
				openSnackbar(`Error during tokenBuyYam: ${error}`, "error");
			}
		}
		callback(null);
		return null;
	};

	const tokenSellSwapCat = async (offer, liveOffer, tokenAmount, callback) => {
		// console.log(offer, liveOffer, tokenAmount);

		let connected = web3active;
		if (!web3active) {
			connected = await connect();
			// await delay(3000);
		}

		if (connected && web3) {
			try {
				// _offerid (uint24)
				// _offertokenamount (uint256)
				// _price (uint256)
				// const smartContract_token_to_buy = new web3.eth.Contract(ERC20ABI, offer.token_to_buy);
				const smartContract_token_to_pay = new web3.eth.Contract(ERC20ABI, offer.token_to_pay);
				const smartContract = new web3.eth.Contract(swapcatABI, swapcatAddress);
				// const digit_to_buy = Number(await smartContract_token_to_buy.methods.decimals().call());
				const digit_to_pay = Number(await smartContract_token_to_pay.methods.decimals().call());

				const _offerid = offer.id_offer;
				const _price = liveOffer.priceInOffer;
				const _offertokenamount = Math.round((tokenAmount / liveOffer.price) * 10 ** digit_to_pay);

				console.log("tokenSellSwapCat", _offerid, "_price", _price, "_offertokenamount", _offertokenamount);

				const result = await smartContract.methods.buy(_offerid, _offertokenamount, _price).send({ from: web3account });
				openSnackbar(`New realToken buy successfully: ${offer.token.shortName}`, "success");
				callback(result);
				return null;
			} catch (error) {
				console.error("Error during tokenSellSwapCat:", error);
				openSnackbar(`Error during tokenSellSwapCat: ${error}`, "error");
			}
		}
		callback(null);
		return null;
	};

	const tokenSellYam = async (offer, liveOffer, tokenAmount, callback) => {
		// console.log("tokenSellYam", offer, liveOffer, tokenAmount);

		let connected = web3active;
		if (!web3active) {
			connected = await connect();
			// await delay(3000);
		}

		if (connected && web3) {
			try {
				// _offerid (uint24)
				// _price (uint256)
				// _offertokenamount (uint256)
				// const smartContract_token_to_buy = new web3.eth.Contract(ERC20ABI, offer.token_to_buy);
				const smartContract_token_to_pay = new web3.eth.Contract(ERC20ABI, offer.token_to_pay);
				const smartContract = new web3.eth.Contract(yamABI, yamAddress);
				// const digit_to_buy = Number(await smartContract_token_to_buy.methods.decimals().call());
				const digit_to_pay = Number(await smartContract_token_to_pay.methods.decimals().call());

				const _offerid = offer.id_offer;
				const _price = liveOffer.priceInOffer;
				const _offertokenamount = Math.round((tokenAmount / liveOffer.price) * 10 ** digit_to_pay);

				console.log("tokenSellYam", _offerid, "_price", _price, "_offertokenamount", _offertokenamount);

				const result = await smartContract.methods.buy(_offerid, _price, _offertokenamount).send({ from: web3account });
				openSnackbar(`New realToken buy successfully: ${offer.token.shortName}`, "success");
				callback(result);
				return null;
			} catch (error) {
				console.error("Error during tokenSellYam:", error);
				openSnackbar(`Error during tokenSellYam: ${error}`, "error");
			}
		}
		callback(null);
		return null;
	};

	const deleteSwapCat = async (_offerid, callback) => {
		let connected = web3active;
		if (!web3active) {
			connected = await connect();
			// await delay(3000);
		}

		if (connected && web3) {
			try {
				// _offerid (uint24)
				const smartContract = new web3.eth.Contract(swapcatABI, swapcatAddress);

				// console.log("deleteSwapCat", _offerid);

				const result = await smartContract.methods.deleteoffer(_offerid).send({ from: web3account });
				openSnackbar(`Delete SwapCat Offer successfully: n°${_offerid}`, "success");
				callback(result);
				return null;
			} catch (error) {
				console.error("Error during deleteSwapCat:", error);
				openSnackbar(`Error during deleteSwapCat: ${error}`, "error");
			}
		}
		callback(null);
		return null;
	};

	const deleteYam = async (_offerid, callback) => {
		let connected = web3active;
		if (!web3active) {
			connected = await connect();
			// await delay(3000);
		}

		if (connected && web3) {
			try {
				// _offerid (uint24)
				const smartContract = new web3.eth.Contract(yamABI, yamAddress);

				// console.log("deleteYam", _offerid);

				const result = await smartContract.methods.deleteOffer(_offerid).send({ from: web3account });
				openSnackbar(`Delete Yam Offer successfully: n°${_offerid}`, "success");
				callback(result);
				return null;
			} catch (error) {
				console.error("Error during deleteYam :", error);
				openSnackbar(`Error during deleteYam: ${error}`, "error");
			}
		}
		callback(null);
		return null;
	};

	const createSwapcat = async (_offertoken, _buyertoken, _price, _offerid, callback) => {
		let connected = web3active;
		if (!web3active) {
			connected = await connect();
			// await delay(3000);
		}

		if (connected && web3) {
			try {
				// _price (uint256)
				const smartContract = new web3.eth.Contract(swapcatABI, swapcatAddress);
				const smartContract_buyertoken = new web3.eth.Contract(ERC20ABI, _buyertoken);
				const digit_buyertoken = Number(await smartContract_buyertoken.methods.decimals().call());
				const price = Math.round(_price * 10 ** digit_buyertoken);

				// console.log("createSwapCat", _offertoken, _buyertoken, price, _offerid);

				const result = await smartContract.methods.makeoffer(_offertoken, _buyertoken, price, _offerid).send({ from: web3account });
				if (_offerid === 0) openSnackbar(`Create new SwapCat Offer successfully`, "success");
				else openSnackbar(`Modify SwapCat Offer successfully: n°${_offerid}`, "success");
				callback(result);
				return null;
			} catch (error) {
				console.error("Error during createSwapcat:", error);
				openSnackbar(`Error during createSwapcat: ${error}`, "error");
			}
		}
		callback(null);
		return null;
	};

	const createYam = async (_offertoken, _buyertoken, _buyerholder, _price, _amount, _offerid, callback) => {
		let connected = web3active;
		if (!web3active) {
			connected = await connect();
			// await delay(3000);
		}

		if (connected && web3) {
			try {
				// _price (uint256)
				const smartContract = new web3.eth.Contract(yamABI, yamAddress);
				const smartContract_offertoken = new web3.eth.Contract(ERC20ABI, _offertoken);
				const smartContract_buyertoken = new web3.eth.Contract(ERC20ABI, _buyertoken);
				const digit_offertoken = Number(await smartContract_offertoken.methods.decimals().call());
				const digit_buyertoken = Number(await smartContract_buyertoken.methods.decimals().call());
				const amount = Math.round(_amount * 10 ** digit_offertoken);
				const price = Math.round(_price * 10 ** digit_buyertoken);

				// console.log("createYam", _offertoken, _buyertoken, _buyerholder, _price, _amount, _offerid, callback);

				if (_offerid === 0) {
					const result = await smartContract.methods.createOffer(_offertoken, _buyertoken, _buyerholder, price, amount).send({ from: web3account });
					openSnackbar(`Create new Yam Offer successfully`, "success");
					callback(result);
					return null;
				} else {
					const result = await smartContract.methods.updateOffer(_offerid, price, amount).send({ from: web3account });
					openSnackbar(`Modify Yam Offer successfully: n°${_offerid}`, "success");
					callback(result);
					return null;
				}
			} catch (error) {
				console.error("Error during createYam:", error);
				openSnackbar(`Error during createYam: ${error}`, "error");
			}
		}
		callback(null);
		return null;
	};
	

	const createYamBatch = async (offerTokens, buyerTokens, buyers, prices, amounts, callback) => {
    let connected = web3active;
    if (!web3active) {
        connected = await connect();
    }

    if (connected && web3 && offerTokens.length > 0) {
			  // Vérifier que tous les tableaux ont la même taille
				const length = offerTokens.length;
    if (
        length !== buyerTokens.length ||
        length !== buyers.length ||
        length !== prices.length ||
        length !== amounts.length
    ) {
        const errorMsg = "Error: All input arrays must have the same length.";
        console.error(errorMsg);
        openSnackbar(errorMsg, "error");
        callback(null);
        return null;
    }
        try {
            const _offertoken = offerTokens;
            const _buyertoken = buyerTokens;
            const _buyerholder = buyers;

            // Préparation des tableaux pour les prix et montants convertis
            const _pricesConverted = [];
            const _amountsConverted = [];

            // Parcourir les tableaux pour effectuer les conversions
            for (let i = 0; i < offerTokens.length; i++) {
                // Récupérer les décimales pour chaque token
                const smartContract_offertoken = new web3.eth.Contract(ERC20ABI, _offertoken[i]);
                const smartContract_buyertoken = new web3.eth.Contract(ERC20ABI, _buyertoken[i]);
                
                const digit_offertoken = Number(await smartContract_offertoken.methods.decimals().call());
                const digit_buyertoken = Number(await smartContract_buyertoken.methods.decimals().call());

                // Convertir le montant et le prix avec les décimales appropriées
                const convertedAmount = Math.round(amounts[i] * 10 ** digit_offertoken);
                const convertedPrice = Math.round(prices[i] * 10 ** digit_buyertoken);

                _amountsConverted.push(convertedAmount);
                _pricesConverted.push(convertedPrice);
            }

            // Créer la liste des nouvelles offres avec la méthode batch
            const smartContract = new web3.eth.Contract(yamABI, yamAddress);
            const result = await smartContract.methods
                .createOfferBatch(_offertoken, _buyertoken, _buyerholder, _pricesConverted, _amountsConverted)
                .send({ from: web3account });

            openSnackbar(`Create new Yam Offer successfully`, "success");
            callback(result);
            return null;
        } catch (error) {
            console.error("Error during createYamBatch:", error);
            openSnackbar(`Error during createYamBatch: ${error.message}`, "error");
        }
    }
    callback(null);
    return null;
};

	

	const sendTransaction = async (to, value) => {
		if (web3 && web3account) {
			try {
				const tx = {
					from: web3account,
					to: to,
					value: web3.utils.toWei(value, "ether"),
				};
				const result = await web3.eth.sendTransaction(tx);
				return result;
			} catch (error) {
				console.error("Erreur lors de l'envoi de la transaction", error);
				return null;
			}
		}
		return null;
	};

	useEffect(() => {
		if (window.ethereum) {
			window.ethereum.on("chainChanged", (chainId) => {
				changeNetwork(chainId);
			});
		}
	}, []);

	useEffect(() => {
		if (window.ethereum) {
			window.ethereum.on("accountsChanged", connect);
			window.ethereum.on("disconnect", () => setWeb3Active(false));
		}
		// if (!web3 && window.ethereum) {
		// 	connect(); // Essaie de te connecter automatiquement si web3 est null
		// }
	}, [web3]);

	useEffect(() => {
		selectNetwork();
	}, [settingsWallet.selectedBlockchain]);

	const value = {
		web3,
		web3account,
		web3accountName,
		web3active,
		web3smartContract: {
			yamAddress,
			swapcatAddress,
			yamABI,
			swapcatABI,
			ERC20ABI,
		},
		web3functions: {
			connect,
			disconnect,
			selectNetwork,
			getBalance,
			getTokenBalance,
			tokenApprove,
			tokenBuyYam,
			tokenBuySwapCat,
			tokenSellSwapCat,
			tokenSellYam,
			deleteSwapCat,
			deleteYam,
			createSwapcat,
			createYam,createYamBatch,
			sendTransaction,
		},
	};

	return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>;
};
