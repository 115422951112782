import React, { createContext, useEffect, useState, useRef, useContext } from "react";
import { SnackbarContext } from "../context/customsnackbar-context";
import { createClient, fetchExchange } from "urql";
import { cacheExchange } from "@urql/exchange-graphcache";
import { SettingsContext } from "./settings-context";

const APIURL_PITSWAP = "https://pitswap-api.herokuapp.com/api/";

const api_key_prod = "27de416cae27059ae17eda857342bf95";
const api_key_dev = "a08814212f9cf4c6d2911a0e536e1ac4";

const APIURL_WHITELIST = `https://ehpst.duckdns.org/realt_rent_tracker/api/whitelist2/`;
//                        https://ehpst.duckdns.org/realt_rent_tracker/api/whitelist/0xe5f43ba510d3ea0d7288ec9f10fce02a65eff4f7

// const APIURL_EHPST_HOLDER_RENT = "https://ehpst.duckdns.org/realt_rent_tracker/holder"; // + (token.uuid1 / + token.uuid2)
// const APIURL_EHPST_TOKEN_RENT = "https://ehpst.duckdns.org/realt_rent_tracker/token/"; // + (token.uuid)

export const RealTokensContext = createContext();

export const RealTokensProvider = (props) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { realTokens, setRealTokens, blockchainsClient, holderWallets, followWallets } = useContext(SettingsContext);
	const { balanceWallets, setBalanceWallets, historyWallets, setHistoryWallets } = useContext(SettingsContext);
	const { settingsWallet, setSettingsWallet, settingsMarket, setSettingsMarket, settingsApiCoinGecko } = useContext(SettingsContext);
	const [last_update, setLast_update] = useState(0);
	const [api_key, setApi_key] = useState(api_key_prod);

	const APIURL_GNOSIS = "https://gateway-arbitrum.network.thegraph.com/api/" + api_key + "/subgraphs/id/FPPoFB7S2dcCNrRyjM5QbaMwKqRZPdbTg8ysBrwXd4SP";
	const APIURL_ETHEREUM =
		"https://gateway-arbitrum.network.thegraph.com/api/" + api_key + "/subgraphs/id/EVjGN4mMd9h9JfGR7yLC6T2xrJf9syhjQNboFb7GzxVW";
	const APIURL_RMM = "https://gateway-arbitrum.network.thegraph.com/api/" + api_key + "/subgraphs/id/9Ut97U2oMKwRucppj7fdMeZ8oFCJrUYyr8wu4hFXBn7Y";
	const APIURL_RMMv3 = "https://gateway-arbitrum.network.thegraph.com/api/" + api_key + "/subgraphs/id/2dMMk7DbQYPX6Gi5siJm6EZ2gDQBF8nJcgKtpiPnPBsK";
	const APIURL_POOL = "https://gateway-arbitrum.network.thegraph.com/api/" + api_key + "/subgraphs/id/74uVnuUvRFFoBY14c4uYhDGwk1stuna9TiDaGTuRAtiE";

	const client_xdai = createClient({
		url: APIURL_GNOSIS,
		exchanges: [
			// dedupExchange,
			cacheExchange({
				/* configuration de la mise en cache */
			}),
			fetchExchange,
		],
	});
	const client_eth = createClient({
		url: APIURL_ETHEREUM,
		exchanges: [
			// dedupExchange,
			cacheExchange({
				/* configuration de la mise en cache */
			}),
			fetchExchange,
		],
	});
	const client_rmm = createClient({
		url: APIURL_RMM,
		exchanges: [
			// dedupExchange,
			cacheExchange({
				/* configuration de la mise en cache */
			}),
			fetchExchange,
		],
	});
	const client_rmmv3 = createClient({
		url: APIURL_RMMv3,
		exchanges: [
			// dedupExchange,
			cacheExchange({
				/* configuration de la mise en cache */
			}),
			fetchExchange,
		],
	});
	const client_pool = createClient({
		url: APIURL_POOL,
		exchanges: [
			// dedupExchange,
			cacheExchange({
				/* configuration de la mise en cache */
			}),
			fetchExchange,
		],
	});

	// -------------------------------------------------------
	// ---- Timer pour mise à jour des prix des tokens    ----
	// -------------------------------------------------------
	const TIMEOUT_REFRESH = 5000; // Timer pour le refresh des offres en millisecondes
	const TIMEOUT_FETCH = 10000; // Timer pour la vérif de refresh des convert/balance en millisecondes
	const UPDATE_REALTOKENS = settingsWallet.timerApiUpdate; // Timer pour le refresh de la date de UPDATE de l'API
	const GET_REALTOKENS = settingsWallet.timeoutApiGet; // Timer pour le refresh de la date de GET de l'API
	const WAIT_REALTOKENS = 1000 * 10; // Timer pour le refresh de la liste des tokens
	const [toUpdateTask, setToUpdateTask] = useState([]);
	const [state1, setState1] = useState(false);
	const [state2, setState2] = useState(false);
	const counter1 = useRef(1);
	const counter2 = useRef(1);

	// -------------------------------------------------------------------------------------------
	// ---- Timer 1 pour la creation des tache à realiser pour les update de l'application    ----
	// -------------------------------------------------------------------------------------------
	useEffect(() => {
		// console.log("useEffect RealTokensContext counter1", counter1.current);
		const timer_balanceWallets = holderWallets ? settingsWallet.timerUpdateTokenHolderWallets : settingsWallet.timerUpdateTokenFollowWallets;
		const timer_whiteList = settingsWallet.timerUpdateWhiteListTokens;

		const addTask = (taskName) => {
			setToUpdateTask((prevTasks) => {
				const taskExists = prevTasks.some((task) => task.name === taskName);
				if (!taskExists) {
					const newTask = { name: taskName, timeStart: new Date().getTime(), timeStop: 0 };
					return [...prevTasks, newTask];
				}
				return prevTasks;
			});
		};

		if (!realTokens) addTask("get_realTokens");
		else {
			if (realTokens.list.length === 0) addTask("get_realTokens");
			else {
				if (!realTokens.timestampUpdate) addTask("get_last_update_realTokens");
				else if (realTokens.timestampUpdate + UPDATE_REALTOKENS < Date.now()) addTask("get_last_update_realTokens");
				else if (realTokens.timestampGet + GET_REALTOKENS < Date.now() || !realTokens.timestampGet) addTask("get_last_get_realTokens");
				else if (realTokens.timestamp + WAIT_REALTOKENS < realTokens.last_update) addTask("get_realTokens");
				else if (realTokens.timestamp + WAIT_REALTOKENS < realTokens.last_get) addTask("get_realTokens");

				if (holderWallets && !balanceWallets) addTask("update_Wallets");

				if (blockchainsClient && balanceWallets && holderWallets) {
					let token = {};
					holderWallets.forEach((wallet) => {
						if (!balanceWallets[wallet.address]) addTask("update_Wallets");
						else if (!balanceWallets[wallet.address].tokens) addTask("update_Wallets");
						else
							for (const chain of Object.keys(balanceWallets[wallet.address].tokens)) {
								// console.log("  balanceWallets", wallet.address,balanceWallets[wallet.address]);
								token = balanceWallets[wallet.address].tokens[chain];
								if (!token) addTask("update_Wallets");
								else if (!token.timestamp) addTask("update_Wallets");
								else if (token.timestamp + timer_balanceWallets < Date.now() || token.toRefresh) addTask("update_Wallets");
							}
					});

					for (const wallet of Object.keys(balanceWallets)) {
						token = balanceWallets[wallet].whiteList;
						if (!token) addTask("get_WhiteListTokens");
						else if (!token.timestamp) addTask("get_WhiteListTokens");
						else if (token.timestamp + timer_whiteList < Date.now() || token.toRefresh) addTask("get_WhiteListTokens");
					}

					for (const chain of Object.keys(blockchainsClient)) {
						for (const coin of Object.keys(blockchainsClient[chain].coinList)) {
							let Coin = blockchainsClient[chain].coinList[coin];
							if (!Coin) addTask("getCoinsValuesToUsd");
							else if (!("timestamp" in Coin)) addTask("getCoinsValuesToUsd");
							else if (Coin.timestamp + settingsApiCoinGecko.timerUpdateCoinsConvertion < Date.now() || Coin.toRefresh)
								addTask("getCoinsValuesToUsd");
						}
					}

					for (const wallet of Object.keys(balanceWallets)) {
						for (const chain of Object.keys(balanceWallets[wallet].coins)) {
							for (const coin of Object.keys(balanceWallets[wallet].coins[chain])) {
								token = balanceWallets[wallet].coins[chain][coin];
								if (!token) {
									addTask("getCoinsBalance");
									console.log("getCoinsBalance1");
								} else if (!("timestamp" in token)) {
									addTask("getCoinsBalance");
									console.log("getCoinsBalance2");
								} else if (token.timestamp + settingsApiCoinGecko.timerUpdateCoinsBalance < Date.now() || token.toRefresh) {
									addTask("getCoinsBalance");
									// console.log("getCoinsBalance3", wallet, chain, coin);
								}
								if (!("usd" in token)) {
									addTask("getCoinsBalance");
									// console.log("getCoinsBalance4", wallet, chain, coin,token);
								}
							}
						}
					}
				}
				if (!("SwapCatOffersList" in settingsMarket)) addTask("update_Market");
				else if (settingsMarket.SwapCatOffersList.length === 0) addTask("update_Market");
				if (!("YamOffersList" in settingsMarket)) addTask("update_Market");
				else if (settingsMarket.YamOffersList.length === 0) addTask("update_Market");
				// if (counter1.current % 2 === 0) addTask("update_Market");
			}
		}
		counter1.current += 1;
		let timeout = TIMEOUT_REFRESH;
		const timer = setTimeout(() => setState1({ num: counter1.current }), timeout);
		return () => clearTimeout(timer);
	}, [state1]);

	// ---------------------------------------------------------------------------------------------
	// ---- Timer 2 pour l'exécution des taches à realiser pour les update de l'application     ----
	// ---------------------------------------------------------------------------------------------
	useEffect(() => {
		if (toUpdateTask.length > 0) {
			// console.log("List of task(s) RealTokensProvider :", toUpdateTask);
			const [firstTask] = toUpdateTask;

			executeTask(firstTask);
		}

		counter2.current += 1;
		let timeout = TIMEOUT_REFRESH;
		const timer = setTimeout(() => setState2({ num: counter2.current }), timeout);
		return () => clearTimeout(timer);
	}, [state2]);

	useEffect(() => {
		// Vérifier l'URL actuelle
		const hostname = window.location.hostname;

		if (hostname === "localhost" || hostname === "127.0.0.1") {
			console.log("Vous êtes en mode développement sur localhost");
			setApi_key(api_key_dev);
		} else if (hostname === "pitsbi.io") {
			// console.log("Vous êtes en mode production sur pitsbi.io");
		} else {
			console.log("Vous êtes sur un autre environnement :", hostname);
		}
	}, []);

	const executeTask = async (task) => {
		// console.log(`Executing task: ${task.name}`, toUpdateTask);

		if (task.name === "get_realTokens") await get_realTokens();
		if (task.name === "get_last_update_realTokens") await get_last_update_realTokens();
		if (task.name === "get_last_get_realTokens") await get_last_get_realTokens();
		if (task.name === "update_Wallets") await update_Wallets();
		if (task.name === "get_WhiteListTokens") await get_WhiteListTokens();
		if (task.name === "update_Market") await update_Market();
		// update_Market();
	};

	const finishTask = (taskName) => {
		// console.log(`End executing task: ${taskName}`, toUpdateTask);

		setToUpdateTask((prevTasks) => {
			// Remove the task from the queue based on its name
			return prevTasks.filter((task) => task.name !== taskName);
		});
	};

	function sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	// --------------------------------------------------------
	// ---- Reccupération de la liste des realTokens       ----
	// --------------------------------------------------------

	const refresh_realTokens = async () => {
		if (realTokens) {
			let RealTokens = realTokens.map((x) => x);
			RealTokens.timestamp = Date.now() - UPDATE_REALTOKENS;
			RealTokens.last_update = "";
			RealTokens.last_get = "";
			realTokens.list = [];
			if (JSON.stringify(RealTokens) !== JSON.stringify(realTokens)) setRealTokens(RealTokens);
		}
	};

	const get_last_update_realTokens = async () => {
		if (realTokens) {
			let RealTokens = { ...realTokens };
			if (RealTokens.timestampUpdate + UPDATE_REALTOKENS < Date.now() || !RealTokens.timestampUpdate || RealTokens.last_update === "") {
				// console.log("get_last_update_realTokens", RealTokens.last_update);
				RealTokens.timestampUpdate = Date.now();

				try {
					let response = await fetch(APIURL_PITSWAP + "last_update_realTokens");
					if (response.ok) {
						let body = await response.json();
						RealTokens.last_update = new Date(body).getTime();
						// console.log("get_last_update_realTokens", body);
					}
				} catch (error) {
					// TypeError: Failed to fetch
					console.log("get_last_update_realTokens", "- fetch error :", error);
					let RealTokens = { ...realTokens };
					RealTokens.timestampUpdate = Date.now();
					if (JSON.stringify(RealTokens) !== JSON.stringify(realTokens)) setRealTokens(RealTokens);
				}

				if (JSON.stringify(RealTokens) !== JSON.stringify(realTokens)) setRealTokens(RealTokens);
			}
		}
		if (!realTokens) {
			setRealTokens({
				timestampGet: 0,
				timestampUpdate: 0,
				last_update: "",
				last_get: "",
				list: [],
			});
		}
		finishTask("get_last_update_realTokens");
	};

	const get_last_get_realTokens = async () => {
		if (realTokens) {
			let RealTokens = { ...realTokens };
			if (RealTokens.timestampGet + GET_REALTOKENS < Date.now() || !RealTokens.timestampGet || RealTokens.last_get === "") {
				// console.log("get_last_get_realTokens", RealTokens.last_get);
				RealTokens.timestampGet = Date.now();

				try {
					fetch(APIURL_PITSWAP + "last_get_realTokens").then((reponse) => {
						if (reponse.ok) {
							reponse.json().then((body) => {
								RealTokens.last_get = new Date(body).getTime();
								// console.log("get_last_get_realTokens", body);
							});
						}
					});
					if (JSON.stringify(RealTokens) !== JSON.stringify(realTokens)) setRealTokens(RealTokens);
				} catch (error) {
					// TypeError: Failed to fetch
					console.log("get_last_get_realTokens", "- fetch error :", error);
					let RealTokens = { ...realTokens };
					RealTokens.timestampGet = Date.now();
					if (JSON.stringify(RealTokens) !== JSON.stringify(realTokens)) setRealTokens(RealTokens);
				}
			}
		}
		if (!realTokens) {
			setRealTokens({
				timestampGet: 0,
				timestampUpdate: 0,
				last_update: "",
				last_get: "",
				list: [],
			});
		}
		finishTask("get_last_get_realTokens");
	};

	const get_realTokens = async () => {
		// console.time("get_realTokens");
		if (realTokens) {
			if (
				realTokens.timestamp + WAIT_REALTOKENS < realTokens.last_get ||
				realTokens.timestamp + WAIT_REALTOKENS < realTokens.last_update ||
				realTokens.list.length === 0
			) {
				// setLast_update(realTokens.last_update);
				let RealTokens = { ...realTokens };
				// console.log("Update list RealTokens", RealTokens);

				try {
					fetch(APIURL_PITSWAP + "realTokens").then((reponse) => {
						if (reponse.ok) {
							reponse.json().then((body) => {
								RealTokens.list = body;
								RealTokens.timestamp = Date.now();
								if (JSON.stringify(RealTokens) !== JSON.stringify(realTokens)) setRealTokens(RealTokens);
							});
						}
					});
				} catch (error) {
					// TypeError: Failed to fetch
					console.log("get_realTokens", "- fetch error :", error);
				}
			}
		}
		if (!realTokens) {
			setRealTokens({
				timestamp: 0,
				timestampGet: 0,
				last_update: "",
				last_get: "",
				list: [],
			});
		}
		// console.timeEnd("get_realTokens");
		finishTask("get_realTokens");
	};

	// --------------------------------------------------------
	// ---- Reccupération du traffic sur l'API pitswap     ----
	// --------------------------------------------------------

	const get_TrafficAPI = async () => {
		try {
			let request = APIURL_PITSWAP + "get_clients_host";
			// console.log(request);
			let response = await fetch(request);
			if (response.ok) {
				let body = await response.json();
				console.log("get_TrafficAPI n°", body);
				openSnackbar(body.response, body.status);
			}
		} catch (error) {
			console.log("get_TrafficAPI - fetch error:", error);
		}
	};

	// --------------------------------------------------------
	// ---- Reccupération de la liste des offres market    ----
	// --------------------------------------------------------

	const update_Market = async () => {
		if (!settingsMarket.timerUpdateOffers || !settingsMarket.timerRefreshOffers || !settingsMarket.timestamp) {
			setSettingsMarket((prevSettings) => ({
				...prevSettings,
				timerRefreshOffers: 1000 * 15,
				timerUpdateOffers: 1000 * 60 * 5,
				timestamp: Date.now(),
			}));
		}

		if (settingsMarket.timestamp + settingsMarket.timerRefreshOffers < Date.now()) {
			get_MarketSwapCatLastUpdate();
			get_MarketYamLastUpdate();

			// console.log("start update_Market");

			setSettingsMarket((prevSettings) => ({
				...prevSettings,
				timerRefreshOffers: 1000 * 15,
				timerUpdateOffers: 1000 * 60 * 5,
				timestamp: Date.now() + settingsMarket.timerRefreshOffers,
			}));
		}
		finishTask("update_Market");
	};

	const get_MarketSwapCatLastUpdate = async () => {
		try {
			let response = await fetch(APIURL_PITSWAP + "last_update_swapcat_offers");
			if (response.ok) {
				let body = await response.json();
				if (settingsMarket.SwapCatLastUpdate < new Date(body).getTime() || !settingsMarket.SwapCatOffersList) {
					// console.log("get_MarketSwapCatLastDate", new Date(body));
					get_MarketSwapCatCount();
					get_MarketSwapCatOffers();

					setSettingsMarket((prevSettings) => ({
						...prevSettings,
						SwapCatLastUpdateDate: new Date(body),
						SwapCatLastUpdate: new Date(body).getTime(),
					}));
				}
			}
		} catch (error) {
			console.log("get_MarketSwapCatLastDate - fetch error: wallet:", error);
		}
	};

	const get_MarketSwapCatCount = async () => {
		try {
			let request = APIURL_PITSWAP + "get_swapcat_offers_count";
			if (settingsWallet) if (settingsWallet.selectedWallet) request = request + "?wallet=" + settingsWallet.selectedWallet;
			// console.log(request);
			let response = await fetch(request);
			if (response.ok) {
				let body = await response.json();
				// console.log("get_MarketSwapCatCount - body", (body));
				setSettingsMarket((prevSettings) => ({
					...prevSettings,
					SwapCatNbOffers_total: body.total,
					SwapCatNbOffers_running: body.running,
				}));
			}
		} catch (error) {
			console.log("get_MarketSwapCatCount - fetch error: wallet:", error);
		}
	};

	const get_MarketSwapCatOffers = async () => {
		try {
			let request = APIURL_PITSWAP + "get_swapcat_offers";
			if (settingsWallet) if (settingsWallet.selectedWallet) request = request + "?wallet=" + settingsWallet.selectedWallet;
			// console.log(request);
			let response = await fetch(request);
			if (response.ok) {
				let body = await response.json();
				// console.log("get_MarketSwapCatOffers - body", body);
				setSettingsMarket((prevSettings) => ({
					...prevSettings,
					SwapCatOffersList: body,
				}));
			}
		} catch (error) {
			console.log("get_MarketSwapCatOffers - fetch error: wallet:", error);
		}
	};

	const set_MarketSwapCatOfferUpdate = async (offer_number,open=true) => {
		try {
			let request = APIURL_PITSWAP + "swapcat_offer_update?offer_number=" + offer_number;
			let response = await fetch(request);
			if (response.ok) {
				let body = await response.json();
				console.log("set_MarketSwapCatOfferUpdate n°", offer_number, body);
				if(open) openSnackbar(body.response, body.status);
			}
		} catch (error) {
			console.log("set_MarketSwapCatOfferUpdate - fetch error: wallet:", error);
		}
	};

	const get_MarketYamLastUpdate = async () => {
		try {
			let response = await fetch(APIURL_PITSWAP + "last_update_yam_offers");
			if (response.ok) {
				let body = await response.json();
				if (settingsMarket.YamLastUpdate < new Date(body).getTime() || !settingsMarket.YamOffersList) {
					// console.log("get_MarketYamLastUpdate", new Date(body));
					get_MarketYamCount();
					get_MarketYamOffers();

					setSettingsMarket((prevSettings) => ({
						...prevSettings,
						YamLastUpdateDate: new Date(body),
						YamLastUpdate: new Date(body).getTime(),
					}));
				}
			}
		} catch (error) {
			console.log("get_MarketYamLastUpdate - fetch error: wallet:", error);
		}
	};

	const get_MarketYamCount = async () => {
		try {
			let request = APIURL_PITSWAP + "get_yam_offers_count";
			if (settingsWallet) if (settingsWallet.selectedWallet) request = request + "?wallet=" + settingsWallet.selectedWallet;
			// console.log(request);
			let response = await fetch(request);
			if (response.ok) {
				let body = await response.json();
				// console.log("get_MarketYamCount - body", body);
				setSettingsMarket((prevSettings) => ({
					...prevSettings,
					YamNbOffers_total: body.total,
					YamNbOffers_running: body.running,
				}));
			}
		} catch (error) {
			console.log("get_MarketYamCount - fetch error: wallet:", error);
		}
	};

	const get_MarketYamOffers = async () => {
		try {
			let request = APIURL_PITSWAP + "get_yam_offers";
			if (settingsWallet) if (settingsWallet.selectedWallet) request = request + "?wallet=" + settingsWallet.selectedWallet;
			// console.log(request);
			let response = await fetch(request);
			if (response.ok) {
				let body = await response.json();
				// console.log("get_MarketYamOffers - body", body);
				setSettingsMarket((prevSettings) => ({
					...prevSettings,
					YamOffersList: body,
				}));
			}
		} catch (error) {
			console.log("get_MarketYamOffers - fetch error: wallet:", error);
		}
	};

	const set_MarketYamOfferUpdate = async (offer_number,open=true) => {
		try {
			let request = APIURL_PITSWAP + "yam_offer_update?offer_number=" + offer_number;
			// console.log(request);
			let response = await fetch(request);
			if (response.ok) {
				let body = await response.json();
				console.log("set_MarketYamOfferUpdate n°", offer_number, body);
				if(open) openSnackbar(body.response, body.status);
			}
		} catch (error) {
			console.log("set_MarketYamOfferUpdate - fetch error:", error, "offer_number:", offer_number);
		}
	};

	// --------------------------------------------------------
	// ---- Reccupération de la liste des realTokens WL    ----
	// --------------------------------------------------------

	const get_WhiteListTokens = async () => {
		let newBalances = JSON.parse(JSON.stringify(balanceWallets)) || {};
		if (blockchainsClient && balanceWallets) {
			for (const wallet of Object.keys(newBalances)) {
				if (newBalances[wallet].whiteList) {
					if (!settingsWallet.timerUpdateWhiteListTokens) {
						setSettingsWallet((prevSettings) => ({
							...prevSettings,
							timerUpdateWhiteListTokens: 12 * 60 * 60 * 1000, // Par défaut update White List toute les 12h
						}));
					} else {
						const timer = settingsWallet.timerUpdateWhiteListTokens;
						if (newBalances[wallet].whiteList.timestamp + timer < Date.now()) {
							try {
								let response = await fetch(APIURL_WHITELIST + wallet.toLowerCase());
								// console.log("get_WhiteListTokens - response", response);
								if (response.ok) {
									let body = await response.json();
									// Process the body or update data as necessary
									newBalances[wallet].whiteList.timestamp = Date.now(); // Update timestamp on successful fetch
									newBalances[wallet].whiteList.tokens = body.map((wl) => wl.token);
									// console.log("get_WhiteListTokens - body", wallet, newBalances[wallet].whiteList.tokens);
									openSnackbar(`Update realTokens WhiteList of ${wallet.slice(0, 6)}...${wallet.slice(-4)}`, "success");
									//
									// sleep(15000);
									// break;
								}
							} catch (error) {
								console.log("get_WhiteListTokens - fetch error: wallet:", wallet, error);
								newBalances[wallet].whiteList.timestamp += 1 * 60 * 60 * 1000;
							}
							// sleep(1000);
						}
					}

					if (newBalances[wallet].whiteList.timestamp) {
					}
				} else {
					// console.log("newBalances[", wallet, "].whiteList not exist");
					newBalances[wallet].whiteList = {
						timestamp: 0,
						tokens: [],
					};
				}
			}

			// console.log("Save realTokens balance for", JSON.stringify(newBalances) !== JSON.stringify(balanceWallets), newBalances, balanceWallets);
			if (!balanceWallets) setBalanceWallets(newBalances);
			else if (Object.keys(findJSONValueDifferences(newBalances, balanceWallets)).length !== 0) {
				openSnackbar(`Update of holder Wallets balances done.`, "success");
				setBalanceWallets(newBalances);
			}
		}
		finishTask("get_WhiteListTokens");
	};

	// --------------------------------------------------------
	// ---- Reccupération du contenu des wallets du holder ----
	// --------------------------------------------------------

	const init_holderWallets = () => {
		let newBalances = JSON.parse(JSON.stringify(balanceWallets)) || {};
		if (!newBalances) newBalances = {};
		if (blockchainsClient && holderWallets) {
			holderWallets.forEach((wallet) => {
				if (!newBalances[wallet.address]) {
					newBalances[wallet.address] = {
						tokens: { eth: {}, xdai: {}, rmm: {}, rmmv3: {}, pool: {} },
						coins: { Ethereum: {}, Gnosis: {} },
					};
				} else {
					if (!newBalances[wallet.address].tokens.rmmv3) {
						newBalances[wallet.address].tokens.rmmv3 = {};
					}
				}

				Object.keys(blockchainsClient).forEach((chain) => {
					if (!newBalances[wallet.address].coins[chain]) {
						newBalances[wallet.address].coins[chain] = {};
					}

					Object.keys(blockchainsClient[chain].coinList).forEach((coin) => {
						// console.log("chain", chain, coin, blockchainsClient[chain][coin]);
						if (!newBalances[wallet.address].coins[chain][coin]) {
							newBalances[wallet.address].coins[chain][coin] = {
								balance: 0,
								usd: 0,
								timestamp: 0,
								toRefresh: false,
								isError: false,
							};
						}
					});
				});
			});
			if (!balanceWallets) setBalanceWallets(newBalances);
			else if (Object.keys(findJSONValueDifferences(newBalances, balanceWallets)).length !== 0) setBalanceWallets(newBalances);
		}

		let newHistoBalances = JSON.parse(JSON.stringify(historyWallets)) || {};
		if (blockchainsClient && holderWallets) {
			holderWallets.forEach((wallet) => {
				if (!newHistoBalances[wallet.address]) {
					newHistoBalances[wallet.address] = {
						tokens: { eth: {}, xdai: {}, rmm: {}, pool: {} },
						coins: { Ethereum: {}, Gnosis: {} },
					};
				} else {
					if (!newBalances[wallet.address].tokens.rmmv3) {
						newBalances[wallet.address].tokens.rmmv3 = {};
					}
				}

				Object.keys(blockchainsClient).forEach((chain) => {
					if (!newHistoBalances[wallet.address].coins[chain]) {
						newHistoBalances[wallet.address].coins[chain] = {};
					}

					Object.keys(blockchainsClient[chain].coinList).forEach((coin) => {
						if (!newHistoBalances[wallet.address].coins[chain][coin]) {
							newHistoBalances[wallet.address].coins[chain][coin] = {};
						}
					});
				});
			});
			if (!historyWallets) setHistoryWallets(newHistoBalances);
			else if (Object.keys(findJSONValueDifferences(newHistoBalances, historyWallets)).length !== 0) setHistoryWallets(newHistoBalances);
		}
	};

	const init_followWallets = () => {
		let newBalances = JSON.parse(JSON.stringify(balanceWallets)) || {};
		if (blockchainsClient && followWallets) {
			followWallets.forEach((wallet) => {
				if (!newBalances[wallet.address]) {
					newBalances[wallet.address] = {
						tokens: { eth: {}, xdai: {}, rmm: {}, pool: {} },
						coins: { Ethereum: {}, Gnosis: {} },
					};
				} else {
					if (!newBalances[wallet.address].tokens.rmmv3) {
						newBalances[wallet.address].tokens.rmmv3 = {};
					}
				}

				Object.keys(blockchainsClient).forEach((chain) => {
					if (!newBalances[wallet.address].coins[chain]) {
						newBalances[wallet.address].coins[chain] = {};
					}

					Object.keys(blockchainsClient[chain].coinList).forEach((coin) => {
						newBalances[wallet.address].coins[chain][coin] = {
							balance: 0,
							usd: 0,
							timestamp: 0,
							toRefresh: false,
							isError: false,
						};
					});
				});
			});
			if (!balanceWallets) setBalanceWallets(newBalances);
			else if (Object.keys(findJSONValueDifferences(newBalances, balanceWallets)).length !== 0) setBalanceWallets(newBalances);
		}

		let newHistoBalances = JSON.parse(JSON.stringify(historyWallets)) || {};
		if (blockchainsClient && followWallets) {
			followWallets.forEach((wallet) => {
				if (!newHistoBalances[wallet.address]) {
					newHistoBalances[wallet.address] = {
						tokens: { eth: {}, xdai: {}, rmm: {}, pool: {} },
						coins: { Ethereum: {}, Gnosis: {} },
					};
				} else {
					if (!newBalances[wallet.address].tokens.rmmv3) {
						newBalances[wallet.address].tokens.rmmv3 = {};
					}
				}

				Object.keys(blockchainsClient).forEach((chain) => {
					if (!newHistoBalances[wallet.address][chain]) {
						newHistoBalances[wallet.address][chain] = {};
					}

					Object.keys(blockchainsClient[chain].coinList).forEach((coin) => {
						newHistoBalances[wallet.address].coins[chain][coin] = {};
					});
				});
			});
			if (!historyWallets) setHistoryWallets(newHistoBalances);
			else if (Object.keys(findJSONValueDifferences(newHistoBalances, historyWallets)).length !== 0) setHistoryWallets(newHistoBalances);
		}
	};

	const refresh_tokens_rmm_balance = async (client, wallet, chain) => {
		const data = [];

		try {
			// for (let skip = 0, nb_response = 0; nb_response % 100 === 0 || nb_response % 100 === 100; skip += 100) {
			const query = `{
					user(id: "${wallet}") {
						id
						reserves(where: {currentATokenBalance_gt: "0"}) {
							reserve {
								underlyingAsset
								decimals
							}
							currentATokenBalance
						}
					}
				}`;

			// console.log("query rmm", query);
			const response = await client.query(query).toPromise();

			if (response.data && response.data.user) {
				// console.log("data rmm:", response.data);
				// nb_response += response.data.user.length;
				data.push(...response.data.user.reserves);
			} else {
				console.log(`${wallet}: thegraph-rmm: Pas de réponses...`);
				// break; // Exit the loop if there are no responses
			}
			// }
		} catch (error) {
			console.error("Error in refresh_tokens_rmm_balance:", error);
		}
		// console.log("data data rmm:", data);
		return data.filter((d) => d.reserve.underlyingAsset !== "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d");
	};
	const refresh_tokens_rmmv3_balance = async (client, wallet, chain) => {
		const data = [];
		const first = 1000;
		let skip = 0;
		let nb_response = 0;

		try {
			// for (let skip = 0, nb_response = 0; nb_response % 100 === 0 || nb_response % 100 === 100; skip += 100) {
			do {
				const query = `{
				users(where: {id: "${wallet}"}) {
					balances(where: {amount_gt: "0"}
					         first: ${first}
					         orderBy: amount
					         orderDirection: desc
					         skip: ${skip}
					) {
						amount
						token {
							decimals
							id
						}
					}
				}
			}`;

				// console.log("query rmm", query);
				const response = await client.query(query).toPromise();
				// console.log("response rmm-v3", response);

				if (response.data && response.data.users && response.data.users.length > 0) {
					// console.log("data rmm-v3:", nb_response, response.data.users[0].balances);
					nb_response = response.data.users[0].balances.length;
					data.push(...response.data.users[0].balances);
				} else {
					console.log(`${wallet}: thegraph-rmm-v3: Pas de réponses...`);
					// break; // Exit the loop if there are no responses
				}
				// }

				skip += 1000;
			} while (nb_response === 1000);
		} catch (error) {
			console.error("Error in refresh_tokens_rmmv3_balance:", error);
		}
		// console.log("data data rmm-v3:", data);
		return data;
	};
	const refresh_tokens_pool_balance = async (client, wallet, chain) => {
		const data = [];
		let skip = 0;
		let nb_response = 0;

		try {
			do {
				const query = `{
                users(first: 1, where: {id: "${wallet}"}) {
                    id
                    liquidityPositions(first: 1000, skip: ${skip}, where: {liquidityTokenBalance_gt: "0"}) {
                        id
                        liquidityTokenBalance
                        pair {
                            id
                            token0Price
                            token1Price
                            totalSupply
                            reserve0
                            reserve1
                            token0 {
                                id
                                name
																liquidity
                            }
                            token1 {
                                id
                                name
																liquidity
                            }
                        }
                    }
                }
            }`;

				const response = await client.query(query).toPromise();

				if (response.data && response.data.users.length > 0) {
					const positions = response.data.users[0].liquidityPositions;
					nb_response = positions.length;
					data.push(...positions);
					skip += 1000; // Update skip value if pagination is required
				} else {
					console.log(`${wallet}: thegraph-pool: No responses.`);
					break; // Exit the loop if there are no responses
				}
			} while (nb_response === 1000); // Update this value if you expect exactly 1000 responses per query
		} catch (error) {
			console.error("Error in refresh_tokens_pool_balance:", error);
		}

		return data;
	};
	const refresh_tokens_balance = async (client, wallet, chain) => {
		let data = [];
		const first = 1000;
		let skip = 0;
		let nb_response = 0;
		// console.log("refresh_tokens_balance:", wallet, chain);
		try {
			do {
				const query = `
            {
                accounts(where: {address: "${wallet}"}) {
                    id
                    balances(
                        where: {amount_gt: "0"}
                        first: ${first}
                        orderBy: amount
                        orderDirection: desc
                        skip: ${skip}
                    ) {
                        id
                        token {
                            id
                            address
                        }
                        amount
                    }
                }
            }
            `;

				// console.log("Executing query with skip:", skip, query);
				const response = await client.query(query).toPromise();

				if (response.data && response.data.accounts.length > 0) {
					const balances = response.data.accounts[0].balances;
					nb_response = balances.length;
					data.push(...balances);
					// console.log("Data fetched for skip", skip, ":", data);
				} else {
					console.log(`${wallet} ${chain}: No more data to fetch for refresh_tokens_balance`);
					break;
				}

				skip += 1000;
			} while (nb_response === 1000);
		} catch (error) {
			console.error("Error in refresh_tokens_balance:", error);
		}

		return data;
	};
	const refresh_realtokens_balance = async (wallet, chain) => {
		try {
			// console.log("refresh_realtokens_balance ->", wallet, chain);
			if (chain === "eth") return await refresh_tokens_balance(client_eth, wallet, chain);
			if (chain === "xdai") return await refresh_tokens_balance(client_xdai, wallet, chain);
			if (chain === "rmm") return await refresh_tokens_rmm_balance(client_rmm, wallet, chain);
			if (chain === "rmmv3") return await refresh_tokens_rmmv3_balance(client_rmmv3, wallet, chain);
			if (chain === "pool") return await refresh_tokens_pool_balance(client_pool, wallet, chain);
		} catch (error) {
			console.error("Error in refresh_realtokens_balance:", error);
		}
	};
	const get_realTokens_balance = async () => {
		const start_time = Date.now();
		let newBalances = JSON.parse(JSON.stringify(balanceWallets)) || {};
		let newHistoBalances = JSON.parse(JSON.stringify(historyWallets)) || {};
		let isModify = false;
		if (blockchainsClient && balanceWallets) {
			for (const wallet of Object.keys(newBalances)) {
				if (start_time + TIMEOUT_FETCH > Date.now()) {
					if (newBalances[wallet].tokens && newHistoBalances[wallet])
						for (const chain of Object.keys(newBalances[wallet].tokens)) {
							isModify = false;
							let tokens = JSON.parse(JSON.stringify(newBalances[wallet].tokens[chain])) || {};
							if (!newHistoBalances[wallet].tokens[chain]) newHistoBalances[wallet].tokens[chain] = {};
							let tokensHisto = JSON.parse(JSON.stringify(newHistoBalances[wallet].tokens[chain])) || {};
							if (tokens && tokensHisto) {
								const holderWalletAddresses = holderWallets.map((w) => w.address);
								const holderWallet = holderWalletAddresses.includes(wallet);
								let  timer = holderWallet ? settingsWallet.timerUpdateTokenHolderWallets : settingsWallet.timerUpdateTokenFollowWallets;

								if (!tokens.timestamp) {
									tokens = { timestamp: 0, tokens: [], toRefresh: false };
								}

								if(tokens.tokens.length===0) timer =  settingsWallet.timerUpdateTokenFollowWallets

								// console.log("tokens.timestamp", wallet, chain);
								// console.log("tokens.timestamp", tokens.timestamp + timer < Date.now(), tokens.timestamp + timer- Date.now(), tokens.toRefresh);

								if (tokens.timestamp + timer < Date.now() || tokens.toRefresh) {
									try {
										const tokensList = await refresh_realtokens_balance(wallet, chain);
										if (tokensList) {
											const currentTimestamp = Date.now();

											tokens = { timestamp: currentTimestamp, tokens: tokensList, toRefresh: false };
											// if (tokens.tokens.length > 0) tokensHisto[currentTimestamp] = { tokens: tokens.tokens };

											newBalances[wallet].tokens[chain] = tokens;
											newHistoBalances[wallet].tokens[chain] = tokensHisto;

											console.log("Updating realTokens balance for", wallet, chain, newBalances[wallet]);

											// Clean up token history
											newHistoBalances[wallet].tokens[chain] = cleanUpTokenHistory(newHistoBalances[wallet].tokens[chain], wallet);

											if (!balanceWallets[wallet].tokens[chain]) {
												openSnackbar(`Create realTokens balance of ${wallet.slice(0, 6)}...${wallet.slice(-4)} on chain ${chain}`, "success");
											} else {
												if (
													Object.keys(findJSONValueDifferences(newBalances[wallet].tokens[chain].tokens, balanceWallets[wallet].tokens[chain].tokens))
														.length !== 0
												)
													isModify = true;
											}
											if (isModify) {
												openSnackbar(`Update realTokens balance of ${wallet.slice(0, 6)}...${wallet.slice(-4)} on chain ${chain}`, "success");
											}
										}
									} catch (error) {
										console.error("Error in get_realTokens_balance:", error);
									}
								}
							}
						}
				} else {
					break;
				}
			}

			// console.log("Save realTokens balance for", JSON.stringify(newBalances) !== JSON.stringify(balanceWallets), newBalances, balanceWallets);
			if (!balanceWallets) setBalanceWallets(newBalances);
			else if (Object.keys(findJSONValueDifferences(newBalances, balanceWallets)).length !== 0) {
				openSnackbar(`Update of holder Wallets balances done.`, "success");
				setBalanceWallets(newBalances);
			}
			if (!historyWallets) setHistoryWallets(newHistoBalances);
			else if (Object.keys(findJSONValueDifferences(newHistoBalances, historyWallets)).length !== 0) {
				openSnackbar(`Update of Archive holder Wallets balances done.`, "success");
				setHistoryWallets(newHistoBalances);
			}
		}
	};

	function findJSONValueDifferences(obj1, obj2, path = "") {
		const differences = {};

		if (!obj2) {
			// console.log(["Object n°2 is empty, update the wallet !!!"]);
			return ["Object n°2 is empty, update the wallet !!!"];
		}

		for (const key in obj1) {
			if (obj1.hasOwnProperty(key)) {
				const value1 = obj1[key];
				const value2 = obj2[key];

				if (typeof value1 === "object" && typeof value2 === "object") {
					const nestedDifferences = findJSONValueDifferences(value1, value2, `${path}.${key}`);
					if (Object.keys(nestedDifferences).length > 0) {
						differences[key] = nestedDifferences;
					}
				} else if (value1 !== value2) {
					differences[`${path}.${key}`] = {
						oldValue: value1,
						newValue: value2,
					};
				}
			}
		}

		for (const key in obj2) {
			if (obj2.hasOwnProperty(key)) {
				if (!obj1.hasOwnProperty(key)) {
					differences[`${path}.${key}`] = {
						oldValue: undefined,
						newValue: obj2[key],
					};
				}
			}
		}

		// if (path === "") console.log("differences", path, differences);
		return differences;
	}

	const cleanUpTokenHistory = (tokensHisto, wallet) => {
		const holderWalletAddresses = holderWallets.map((w) => w.address);
		const holderWallet = holderWalletAddresses.includes(wallet);
		const timerDelete = holderWallet ? settingsWallet.timerDeleteTokenHolderWallets : settingsWallet.timerDeleteTokenFollowWallets;
		const keys = Object.keys(tokensHisto).sort((a, b) => a - b); // Sort timestamps

		let previousTokens = null;
		keys.forEach((timestamp) => {
			const entry = tokensHisto[timestamp];

			// Remove if duplicate
			if (previousTokens && JSON.stringify(entry.tokens) === JSON.stringify(previousTokens)) {
				delete tokensHisto[timestamp];
			} else {
				previousTokens = entry.tokens;
			}

			// Remove if older than 30 days
			if (Date.now() - timestamp > timerDelete) {
				delete tokensHisto[timestamp];
			}
		});

		return tokensHisto;
	};

	const update_Wallets = async () => {
		init_holderWallets();
		init_followWallets();

		get_realTokens_balance();

		finishTask("update_Wallets");
	};

	const refresh_holderWallets = () => {
		// setBalanceWallets(null);
		const newBalances = { ...balanceWallets };
		if (blockchainsClient) {
			for (const wallet of Object.keys(newBalances)) {
				console.log("refresh_holderWallets:", newBalances[wallet]);
				for (const chain of Object.keys(newBalances[wallet].tokens)) {
					let tokens = newBalances[wallet].tokens[chain];
					if (!tokens.tokens) tokens.tokens = [];
					tokens.toRefresh = true;
					tokens.timestamp = 0;
					console.log("refresh_holderWallets:", wallet, chain, tokens);
				}
				if (newBalances[wallet].whiteList) {
					newBalances[wallet].whiteList.timestamp = 0;
				}
			}
			if (JSON.stringify(newBalances) !== JSON.stringify(balanceWallets)) setBalanceWallets(newBalances);
		}
	};

	const value = {
		refresh_realTokens,
		refresh_holderWallets,
		last_update,
		setLast_update,
		finishTask,
		toUpdateTask,
		setToUpdateTask,
		set_MarketSwapCatOfferUpdate,
		set_MarketYamOfferUpdate,
		get_TrafficAPI,
	};

	return <RealTokensContext.Provider value={value}>{props.children}</RealTokensContext.Provider>;
};
