import React, { useContext, useState, useEffect } from "react";
import { SettingsContext, balanceWallets, settingsWallet } from "../../../context/settings-context";
import { MyThemeContext } from "../../../context/theme-context";
import { SnackbarContext } from "../../../context/customsnackbar-context";
import { RealTokensContext } from "../../../context/realTokens-context";
import { Web3Context } from "../../../context/web3-context";
import Web3 from "web3";

import {
	Box,
	Slide,
	Dialog,
	Button,
	Typography,
	TextField,
	useMediaQuery,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Avatar,
	Menu,
	MenuItem,
	CircularProgress,
} from "@mui/material";
import { green, red, blue, orange } from "@mui/material/colors";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CloseIcon from "@mui/icons-material/Close";

import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FormatedNumber from "../../Common/Formater/FormatedNumber";
import FormatedNumberDigit from "../../Common/Formater/FormatedNumberDigit";
import FormatedPrice from "../../Common/Formater/FormatedPrice";
import FormatedPercentage from "../../Common/Formater/FormatedPercentage";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

function BuyWeb3Offer({ open, setOpen, offer, index }) {
	const { web3active, web3functions } = useContext(Web3Context);
	const { blockchainsClient, settingsWallet } = useContext(SettingsContext);
	const { set_MarketSwapCatOfferUpdate, set_MarketYamOfferUpdate } = useContext(RealTokensContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const { themeContext } = useContext(MyThemeContext);
	const [isUpdate, setIsUpdate] = useState(false);
	const [buyAmount, setBuyAmount] = useState(0);
	const [allowance, setAllowance] = useState(0);

	const [changeAllowance, setChangeAllowance] = useState(false);
	const [anchorElAddToCart, setAnchorElAddToCart] = useState(null);
	const [anchorElBuyToken, setAnchorElBuyToken] = useState(null);
	const [waitTransaction, setWaitTransaction] = useState(false);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	const handleCloseAddToCart = () => {
		setAnchorElAddToCart(null);
	};

	const handleCloseBuyToken = () => {
		setAnchorElBuyToken(null);
	};

	const handleClose = () => {
		setIsUpdate(false);
		setLiveOffer(null);
		setOpen(false);
	};

	let weeks_delay = 0;
	if (offer.token_value_usd - offer.tokenPrice > 0) {
		weeks_delay = (offer.token_value_usd - offer.tokenPrice) / ((offer.tokenPrice * offer.annualPercentageYield) / 100 / 52);
		// console.log("weeks_delay", weeks_delay, "week(s)");
	}

	const bc = blockchainsClient["Gnosis"];
	const _web3 = new Web3(bc.rpc);
	const yamAddress = "0xC759AA7f9dd9720A1502c104DaE4F9852bb17C14".toLowerCase();
	const swapcatAddress = "0xB18713Ac02Fc2090c0447e539524a5c76f327a3b".toLowerCase();
	const yamABI = [
		{ inputs: [], stateMutability: "nonpayable", type: "constructor" },
		{
			anonymous: false,
			inputs: [
				{ indexed: false, internalType: "address", name: "previousAdmin", type: "address" },
				{ indexed: false, internalType: "address", name: "newAdmin", type: "address" },
			],
			name: "AdminChanged",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [{ indexed: true, internalType: "address", name: "beacon", type: "address" }],
			name: "BeaconUpgraded",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "uint256", name: "oldFee", type: "uint256" },
				{ indexed: true, internalType: "uint256", name: "newFee", type: "uint256" },
			],
			name: "FeeChanged",
			type: "event",
		},
		{ anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" },
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "uint256", name: "offerId", type: "uint256" },
				{ indexed: true, internalType: "address", name: "seller", type: "address" },
				{ indexed: true, internalType: "address", name: "buyer", type: "address" },
				{ indexed: false, internalType: "address", name: "offerToken", type: "address" },
				{ indexed: false, internalType: "address", name: "buyerToken", type: "address" },
				{ indexed: false, internalType: "uint256", name: "price", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "OfferAccepted",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "address", name: "offerToken", type: "address" },
				{ indexed: true, internalType: "address", name: "buyerToken", type: "address" },
				{ indexed: false, internalType: "address", name: "seller", type: "address" },
				{ indexed: false, internalType: "address", name: "buyer", type: "address" },
				{ indexed: true, internalType: "uint256", name: "offerId", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "price", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "OfferCreated",
			type: "event",
		},
		{ anonymous: false, inputs: [{ indexed: true, internalType: "uint256", name: "offerId", type: "uint256" }], name: "OfferDeleted", type: "event" },
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "uint256", name: "offerId", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "oldPrice", type: "uint256" },
				{ indexed: true, internalType: "uint256", name: "newPrice", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "oldAmount", type: "uint256" },
				{ indexed: true, internalType: "uint256", name: "newAmount", type: "uint256" },
			],
			name: "OfferUpdated",
			type: "event",
		},
		{ anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }], name: "Paused", type: "event" },
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
				{ indexed: true, internalType: "bytes32", name: "previousAdminRole", type: "bytes32" },
				{ indexed: true, internalType: "bytes32", name: "newAdminRole", type: "bytes32" },
			],
			name: "RoleAdminChanged",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
				{ indexed: true, internalType: "address", name: "account", type: "address" },
				{ indexed: true, internalType: "address", name: "sender", type: "address" },
			],
			name: "RoleGranted",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
				{ indexed: true, internalType: "address", name: "account", type: "address" },
				{ indexed: true, internalType: "address", name: "sender", type: "address" },
			],
			name: "RoleRevoked",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "address[]", name: "tokens", type: "address[]" },
				{ indexed: true, internalType: "enum IRealTokenYamUpgradeableV3.TokenType[]", name: "types", type: "uint8[]" },
			],
			name: "TokenWhitelistWithTypeToggled",
			type: "event",
		},
		{ anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }], name: "Unpaused", type: "event" },
		{
			anonymous: false,
			inputs: [{ indexed: true, internalType: "address", name: "implementation", type: "address" }],
			name: "Upgraded",
			type: "event",
		},
		{
			inputs: [],
			name: "DEFAULT_ADMIN_ROLE",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "MODERATOR_ROLE",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "UPGRADER_ROLE",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "buy",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256[]", name: "_offerIds", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_prices", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
			],
			name: "buyOfferBatch",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
				{ internalType: "uint256", name: "deadline", type: "uint256" },
				{ internalType: "uint8", name: "v", type: "uint8" },
				{ internalType: "bytes32", name: "r", type: "bytes32" },
				{ internalType: "bytes32", name: "s", type: "bytes32" },
			],
			name: "buyWithPermit",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "offerToken", type: "address" },
				{ internalType: "address", name: "buyerToken", type: "address" },
				{ internalType: "address", name: "buyer", type: "address" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "createOffer",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address[]", name: "_offerTokens", type: "address[]" },
				{ internalType: "address[]", name: "_buyerTokens", type: "address[]" },
				{ internalType: "address[]", name: "_buyers", type: "address[]" },
				{ internalType: "uint256[]", name: "_prices", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
			],
			name: "createOfferBatch",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "offerToken", type: "address" },
				{ internalType: "address", name: "buyerToken", type: "address" },
				{ internalType: "address", name: "buyer", type: "address" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
				{ internalType: "uint256", name: "newAllowance", type: "uint256" },
				{ internalType: "uint256", name: "deadline", type: "uint256" },
				{ internalType: "uint8", name: "v", type: "uint8" },
				{ internalType: "bytes32", name: "r", type: "bytes32" },
				{ internalType: "bytes32", name: "s", type: "bytes32" },
			],
			name: "createOfferWithPermit",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256", name: "offerId", type: "uint256" }],
			name: "deleteOffer",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256[]", name: "offerIds", type: "uint256[]" }],
			name: "deleteOfferBatch",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256[]", name: "offerIds", type: "uint256[]" }],
			name: "deleteOfferByAdmin",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{ inputs: [], name: "fee", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" },
		{
			inputs: [{ internalType: "uint256", name: "offerId", type: "uint256" }],
			name: "getInitialOffer",
			outputs: [
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "uint256", name: "", type: "uint256" },
				{ internalType: "uint256", name: "", type: "uint256" },
			],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "getOfferCount",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
			name: "getRoleAdmin",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [{ internalType: "address", name: "token", type: "address" }],
			name: "getTokenType",
			outputs: [{ internalType: "enum IRealTokenYamUpgradeableV3.TokenType", name: "", type: "uint8" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "bytes32", name: "role", type: "bytes32" },
				{ internalType: "address", name: "account", type: "address" },
			],
			name: "grantRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "bytes32", name: "role", type: "bytes32" },
				{ internalType: "address", name: "account", type: "address" },
			],
			name: "hasRole",
			outputs: [{ internalType: "bool", name: "", type: "bool" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "admin_", type: "address" },
				{ internalType: "address", name: "moderator_", type: "address" },
			],
			name: "initialize",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{ inputs: [], name: "pause", outputs: [], stateMutability: "nonpayable", type: "function" },
		{ inputs: [], name: "paused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" },
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "pricePreview",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "proxiableUUID",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "bytes32", name: "role", type: "bytes32" },
				{ internalType: "address", name: "account", type: "address" },
			],
			name: "renounceRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "bytes32", name: "role", type: "bytes32" },
				{ internalType: "address", name: "account", type: "address" },
			],
			name: "revokeRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "address", name: "token", type: "address" }],
			name: "saveLostTokens",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256", name: "fee_", type: "uint256" }],
			name: "setFee",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256", name: "offerId", type: "uint256" }],
			name: "showOffer",
			outputs: [
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "uint256", name: "", type: "uint256" },
				{ internalType: "uint256", name: "", type: "uint256" },
			],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
			name: "supportsInterface",
			outputs: [{ internalType: "bool", name: "", type: "bool" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address[]", name: "tokens_", type: "address[]" },
				{ internalType: "enum IRealTokenYamUpgradeableV3.TokenType[]", name: "types_", type: "uint8[]" },
			],
			name: "toggleWhitelistWithType",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "address", name: "tokenAddr", type: "address" }],
			name: "tokenInfo",
			outputs: [
				{ internalType: "uint256", name: "", type: "uint256" },
				{ internalType: "string", name: "", type: "string" },
				{ internalType: "string", name: "", type: "string" },
			],
			stateMutability: "view",
			type: "function",
		},
		{ inputs: [], name: "unpause", outputs: [], stateMutability: "nonpayable", type: "function" },
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "updateOffer",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256[]", name: "_offerIds", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_prices", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
			],
			name: "updateOfferBatch",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
				{ internalType: "uint256", name: "newAllowance", type: "uint256" },
				{ internalType: "uint256", name: "deadline", type: "uint256" },
				{ internalType: "uint8", name: "v", type: "uint8" },
				{ internalType: "bytes32", name: "r", type: "bytes32" },
				{ internalType: "bytes32", name: "s", type: "bytes32" },
			],
			name: "updateOfferWithPermit",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "address", name: "newImplementation", type: "address" }],
			name: "upgradeTo",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "newImplementation", type: "address" },
				{ internalType: "bytes", name: "data", type: "bytes" },
			],
			name: "upgradeToAndCall",
			outputs: [],
			stateMutability: "payable",
			type: "function",
		},
	];
	const swapcatABI = [
		{
			constant: true,
			inputs: [{ name: "_tokenaddr", type: "address" }],
			name: "tokeninfo",
			outputs: [
				{ name: "", type: "uint256" },
				{ name: "", type: "string" },
				{ name: "", type: "string" },
			],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		{
			constant: false,
			inputs: [{ name: "token", type: "address" }],
			name: "losttokens",
			outputs: [],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			constant: false,
			inputs: [{ name: "_offerid", type: "uint24" }],
			name: "deleteoffer",
			outputs: [{ name: "", type: "string" }],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			constant: false,
			inputs: [
				{ name: "_offertoken", type: "address" },
				{ name: "_buyertoken", type: "address" },
				{ name: "_price", type: "uint256" },
				{ name: "_offerid", type: "uint24" },
			],
			name: "makeoffer",
			outputs: [{ name: "", type: "uint24" }],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			constant: true,
			inputs: [{ name: "_offerid", type: "uint24" }],
			name: "showoffer",
			outputs: [
				{ name: "", type: "address" },
				{ name: "", type: "address" },
				{ name: "", type: "address" },
				{ name: "", type: "uint256" },
				{ name: "", type: "uint256" },
			],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		{
			constant: true,
			inputs: [
				{ name: "_offerid", type: "uint24" },
				{ name: "_amount", type: "uint256" },
			],
			name: "pricepreview",
			outputs: [{ name: "", type: "uint256" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		{
			constant: false,
			inputs: [
				{ name: "_offerid", type: "uint24" },
				{ name: "_offertokenamount", type: "uint256" },
				{ name: "_price", type: "uint256" },
			],
			name: "buy",
			outputs: [{ name: "", type: "string" }],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			constant: true,
			inputs: [],
			name: "getoffercount",
			outputs: [{ name: "", type: "uint24" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		{ payable: true, stateMutability: "payable", type: "fallback" },
	];
	const ERC20ABI = [
		// BalanceOf function
		{
			constant: true,
			inputs: [{ name: "_owner", type: "address" }],
			name: "balanceOf",
			outputs: [{ name: "balance", type: "uint256" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		// Allowance function
		{
			constant: true,
			inputs: [
				{ name: "_owner", type: "address" },
				{ name: "_spender", type: "address" },
			],
			name: "allowance",
			outputs: [{ name: "remaining", type: "uint256" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		// Decimals function
		{
			constant: true,
			inputs: [],
			name: "decimals",
			outputs: [{ name: "", type: "uint8" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		// Approve function
		{
			constant: false,
			inputs: [
				{ name: "_spender", type: "address" },
				{ name: "_value", type: "uint256" },
			],
			name: "approve",
			outputs: [{ name: "", type: "bool" }],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
	];
	const yamContract = new _web3.eth.Contract(yamABI, yamAddress);
	const swapcatContract = new _web3.eth.Contract(swapcatABI, swapcatAddress);
	// const nbOffersAutoUpdate = 15;

	const getYamOffer = async () => {
		const yamOffer = await yamContract.methods.showOffer(offer.id_offer).call();
		const digitToken0 = await yamContract.methods.tokenInfo(yamOffer["0"]).call();
		const digitToken1 = await yamContract.methods.tokenInfo(yamOffer["1"]).call();
		// console.log("offerYam:", yamOffer);
		const priceOffer = Number(yamOffer["4"]) / 10 ** Number(digitToken1["0"]);
		const amountOffer = Number(yamOffer["5"]) / 10 ** Number(digitToken0["0"]);

		const priceOfferReverse = 10 ** Number(digitToken1["0"]) / Number(yamOffer["4"]);
		let amountReal = priceOffer * amountOffer;

		return {
			id_offer: offer.id_offer,
			price: priceOffer,
			amount: amountOffer,
			amountInOffer: yamOffer["5"],
			priceReverse: priceOfferReverse,
			amountReal: amountReal,
			priceInOffer: yamOffer["4"],
			coinAddress: yamOffer["1"].toLowerCase(),
			spender: yamAddress,
		};
	};
	
	const getSwapcatOffer = async () => {
		const swapcatOffer = await swapcatContract.methods.showoffer(offer.id_offer).call();
		const digitToken0 = await swapcatContract.methods.tokeninfo(swapcatOffer["0"]).call();
		const digitToken1 = await swapcatContract.methods.tokeninfo(swapcatOffer["1"]).call();
		// console.log("swapcatOffer:", swapcatOffer);
		const priceOffer = Number(swapcatOffer["3"]) / 10 ** Number(digitToken1["0"]);
		const amountOffer = Number(swapcatOffer["4"]) / 10 ** Number(digitToken0["0"]);

		const priceOfferReverse = 10 ** Number(digitToken1["0"]) / Number(swapcatOffer["3"]);
		let amountReal = priceOffer * amountOffer;

		return {
			id_offer: offer.id_offer,
			amount: amountOffer,
			amountInOffer: swapcatOffer["4"],
			price: priceOffer,
			priceReverse: priceOfferReverse,
			priceInOffer: swapcatOffer["3"],
			amountReal: amountReal,
			coinAddress: swapcatOffer["1"].toLowerCase(),
			spender: swapcatAddress,
		};
	};

	const getAllowanceCoin = async (coinAddress, spender) => {
		const coinContract = new _web3.eth.Contract(ERC20ABI, coinAddress);
		const allowance = Number(await coinContract.methods.allowance(settingsWallet.selectedWallet.toLowerCase(), spender).call());
		const decimals = Number(await coinContract.methods.decimals().call());
		const formattedAllowance = allowance / 10 ** decimals;
		return formattedAllowance;
	};

	const getDigitAllowanceCoin = async (coinAddress) => {
		const coinContract = new _web3.eth.Contract(ERC20ABI, coinAddress);
		const digit = Number(await coinContract.methods.decimals().call());
		return digit;
	};
	const getTokenBalance = async (tokenAddress) => {
		const tokenContract = new _web3.eth.Contract(ERC20ABI, tokenAddress);
		const balance = Number(await tokenContract.methods.balanceOf(settingsWallet.selectedWallet.toLowerCase()).call());
		const decimals = Number(await tokenContract.methods.decimals().call());
		const formattedBalance = balance / 10 ** decimals;
		return formattedBalance;
	};
	const [liveOffer, setLiveOffer] = useState(null);
	const [allowanceCoin, setAllowanceCoin] = useState(null);
	const [digitCoin, setDigitCoin] = useState(18);
	const [coinInMyWallet, setCoinInMyWallet] = useState(null);

	
	let premium = 0;
	if (offer && liveOffer) {
		premium = buyAmount * (offer.token_value_usd-offer.tokenPrice);
		// console.log("premium", premium, "$",!premium<0.01 , !premium>=-0.01);
	}

	useEffect(() => {
		const fetchOffer = async () => {
			// if (open || index < nbOffersAutoUpdate) {
			if (open) {
				try {
					if (liveOffer)
						if (offer.id_offer !== liveOffer.id_offer) console.log("offer", offer.id_offer, "liveOffer", liveOffer.id_offer);
						else console.log("update offer", offer.id_offer);
					if (!liveOffer || offer.id_offer !== liveOffer.id_offer) {
						if (offer.sc === "yam") {
							let offerData = await getYamOffer();
							setLiveOffer(offerData);
						}
						if (offer.sc === "swapcat") {
							let offerData = await getSwapcatOffer();
							setLiveOffer(offerData);
						}
					}
				} catch (error) {
					console.log("fetchOffer", "-  getOffer error :", error);
					if (open) openSnackbar("The offer is delete. An update request has been sent to the API", "error");
					if (offer.sc === "yam") await set_MarketYamOfferUpdate(offer.id_offer, open);
					if (offer.sc === "swapcat") await set_MarketSwapCatOfferUpdate(offer.id_offer, open);
					handleClose();
				}

				try {
					if (!allowanceCoin && liveOffer) {
						const allowance = await getAllowanceCoin(liveOffer.coinAddress, liveOffer.spender);
						const digit = await getDigitAllowanceCoin(liveOffer.coinAddress);
						const myCoin = await getTokenBalance(liveOffer.coinAddress);
						// console.log("allowanceCoin:", allowance, "coinInMyWallet:", myCoin);
						setAllowanceCoin(allowance);
						setDigitCoin(digit);
						setCoinInMyWallet(myCoin);
						if(!changeAllowance) setAllowance(allowance);
					}
				} catch (error) {
					console.log("fetchOffer", "- getAllowanceCoin error :", error);
				}
			}

			if (open && liveOffer) {
				if (open && !isUpdate && (liveOffer.amountReal * 1.0000001 < offer.token_amount || liveOffer.amountReal * 0.9999999 > offer.token_amount)) {
					console.log("offer:", offer);
					console.log("liveOffer:", liveOffer);
					console.log("liveOffer.amount:", liveOffer.amount);
					console.log("liveOfferReal:", liveOffer.amountReal);
					console.log("offer.token_amount:", offer.token_amount);
					if (open) openSnackbar("The offer is out of sync. An update request has been sent to the API", "error");
					if (offer.sc === "yam") await set_MarketYamOfferUpdate(offer.id_offer, open);
					if (offer.sc === "swapcat") await set_MarketSwapCatOfferUpdate(offer.id_offer, open);
					setIsUpdate(true);
				}
				if (open && isUpdate && liveOffer.amount > offer.token_amount) {
					setLiveOffer(null);
					setIsUpdate(false);
				}
				if (open && isUpdate && liveOffer.amount < offer.token_amount) {
					setLiveOffer(null);
					setIsUpdate(false);
				}
			}
		};

		if (waitTransaction) return;
		fetchOffer();
	}, [open, offer, liveOffer, allowanceCoin, waitTransaction]);

	const handleBuyMaximum = () => {
		let maxBuyableAmount = Math.min(liveOffer.amountReal, coinInMyWallet );
		console.log(liveOffer.amountReal, coinInMyWallet)
		setBuyAmount(maxBuyableAmount);
		handleAllowanceBuyChange(maxBuyableAmount);
	};

	const handleBuyChange = (amountToBuy) => {
		let maxBuyableAmount = Math.min(liveOffer.amountReal, coinInMyWallet);
		console.log(liveOffer.amountReal, coinInMyWallet)
		if (amountToBuy > maxBuyableAmount) {
			setBuyAmount(maxBuyableAmount);
			handleAllowanceBuyChange(maxBuyableAmount);
		} else {
			setBuyAmount(amountToBuy);
			handleAllowanceBuyChange(amountToBuy);
		}
	};

	const handleAllowanceBuyChange = (amountMinimum) => {
		const totalCost = amountMinimum;
		// console.log(buyAmount * liveOffer.price,liveOffer.price)
		if (allowanceCoin < totalCost) {
			setAllowance(totalCost);
			setChangeAllowance(true);
		} else if (changeAllowance) {
			setChangeAllowance(false);
		}
	};

	const handleAllowanceChange = (value) => {
		let minAllowance = Math.min(buyAmount * liveOffer.price, coinInMyWallet);
		if (value < minAllowance) {
			setAllowance(minAllowance);
		} else setAllowance(value);
	};

	const handleBuyToken = (event) => {
		// console.log("handleBuyToken", buyAmount, "token for", buyAmount * liveOffer.price, event);
		setAnchorElBuyToken(event.currentTarget);
	};

	const handleAddToCart = (event) => {
		console.log("handleAddToCart", buyAmount, "token for", buyAmount * liveOffer.price);
		setAnchorElAddToCart(event.currentTarget);
	};

	const handleChangeAllowance = async (event) => {
		const callback = (result) => {
			if (result) {
				console.log("New allowance set successfully:", result);
				setChangeAllowance(false);
			}
			setAllowanceCoin(null);
			setWaitTransaction(false);
		};

		setWaitTransaction(true);
		if (offer.sc === "yam") {
			await web3functions.tokenApprove(offer.token_to_buy, allowance, yamAddress, callback);
		}
		if (offer.sc === "swapcat") {
			await web3functions.tokenApprove(offer.token_to_buy, allowance, swapcatAddress, callback);
		}
	};

	const handleConfirmBuyToken = async (event) => {
		const callback = async (result) => {
			if (result) {
				console.log("New realToken buy successfully:", result);
				setAnchorElBuyToken(null);
				setLiveOffer(null);
				if (offer.sc === "yam") 						await set_MarketYamOfferUpdate(offer.id_offer, open);
				if (offer.sc === "swapcat") 						await set_MarketSwapCatOfferUpdate(offer.id_offer, open);
						
				handleClose();
			}
			setAllowanceCoin(null);
			setWaitTransaction(false);
		};

		setWaitTransaction(true);
		if (offer.sc === "yam") {
			await web3functions.tokenSellYam(offer, liveOffer, buyAmount, callback);
		}
		if (offer.sc === "swapcat") {
			await web3functions.tokenSellSwapCat(offer, liveOffer, buyAmount, callback);
		}
	};

	const handleConfirmAddToCart = (event) => {
		console.log("handleConfirmAddToCart", buyAmount, "token for", buyAmount * liveOffer.price);
		handleCloseAddToCart();
		handleClose();
	};

	if (!open) return <></>;
	if (!offer) return <></>;

	const size_row_height = 42;
	const size_token_to_paid = 20;
	const size_col1 = 125;
	const size_col2 = 160;
	const size_col3 = isMatch ? 70 : 125;
	const size_col4 = "auto";
	const logo_pitsbi_sizeW = 20;
	const logo_pitsbi_sizeH = 25;

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby='alert-dialog-slide-description'
			sx={{
				m: 0,
				width: 1,
				"& .MuiDialog-paper": {
					width: isMatch ? 750 : 750,
					maxWidth: "none",
					border: 0,
					color: blue[600],
					borderRadius: 0,
				},
			}}
		>
			<Box sx={{ m: 0, border: 5, width: 1, color: blue[600], borderRadius: 0 }}>
				<DialogTitle sx={{ m: 0, py: 1.5, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
					<Box sx={{ ml: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
						<Box sx={{ ml: 0, width: 290, border: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>{"Sell a RealToken"}</Box>
						<Box sx={{ ml: 0, width: 290, border: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
							<Box
								component='img'
								alt='SmartContract'
								src={offer.sc === "yam" ? "/images/yam.png" : "/images/swapcat.png"}
								sx={{ mx: 1, width: 24, height: 24 }}
							/>
							{offer.sc === "yam" ? "YAM offer n°" : "Swapcat offer n°"}
							{offer.id_offer}
						</Box>
					</Box>
				</DialogTitle>

				<Box
					sx={{
						px: 1,
						borderTop: 2,
						borderBottom: 2,
						color: blue[600],
						display: "flex",
						flexDirection: "row",
						alignItems: "top",
						justifyContent: "top",
					}}
				>
					<Box component='img' alt='imgToken' src={offer.token.imageLink[0]} sx={{ m: 0, width: 105, height: 105 }} />
					<Box sx={{ ml: 1, pt: 1 }}>
						<Box sx={{ pb: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", cursor: "pointer" }}>
							<Box
								sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
								onClick={() => window.open(offer.token.marketplaceLink, "_blank")}
							>
								<OpenInNewIcon />
							</Box>
							<Typography variant='body1' sx={{ ml: 1, color: blue[600] }} onClick={() => window.open(offer.token.marketplaceLink, "_blank")}>
								{offer.fullName}
							</Typography>
						</Box>

						<Box sx={{ ml: 2, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
							<Box sx={{ ml: 0, width: 90, display: "flex" }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{"Token Price"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: 60 }}>
								<FormatedPrice variant='body2' value={offer.tokenPrice} color={blue[600]} />
							</Box>
							{offer.tokenPrice !== offer.token.historic.init_price && (
								<>
									<Box sx={{ ml: 2, width: 90 }}>
										<Typography variant='body2' sx={{ color: orange[600] }}>
											{"Init Price"}
										</Typography>
									</Box>
									<Box sx={{ ml: 0, width: 60 }}>
										<FormatedPrice variant='body2' value={offer.token.historic.init_price} color={orange[600]} />
									</Box>
								</>
							)}
						</Box>

						<Box sx={{ ml: 2, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
							<Box sx={{ ml: 0, width: 90, display: "flex" }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{"Token Yield"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: 60 }}>
								<FormatedPercentage variant='body2' value={offer.annualPercentageYield / 100} color={blue[600]} />
							</Box>
							{offer.annualPercentageYield !== offer.token.historic.init_yield && (
								<>
									<Box sx={{ ml: 2, width: 90 }}>
										<Typography variant='body2' sx={{ color: orange[600] }}>
											{"Init Yield"}
										</Typography>
									</Box>
									<Box sx={{ ml: 0, width: 60 }}>
										<FormatedPercentage variant='body2' value={offer.token.historic.init_yield / 100} color={orange[600]} />
									</Box>
								</>
							)}
						</Box>

						<Box sx={{ ml: 2, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
							<Box sx={{ ml: 0, width: 90 }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{"Rented"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: 60 }}>
								<FormatedPercentage variant='body2' value={offer.token_rented} color={offer.token_rented < 1 ? red[700] : green[600]} />
							</Box>
							{true && (
								<>
									<Box sx={{ ml: 2, width: 90 }}>
										<Typography
											variant='body2'
											sx={{ color: offer.token.historic.init_yield <= offer.token.historic.avg_yield ? green[700] : red[700] }}
										>
											{"Avg Yield"}
										</Typography>
									</Box>
									<Box sx={{ ml: 0, width: 60 }}>
										<FormatedPercentage
											variant='body2'
											value={offer.token.historic.avg_yield / 100}
											color={offer.token.historic.init_yield <= offer.token.historic.avg_yield ? green[700] : red[700]}
										/>
									</Box>
								</>
							)}
						</Box>
					</Box>
					<Box sx={{ ml: "auto", width: 50, border: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
						<Avatar sx={{ bgcolor: offer.whiteList ? green[500] : red[700], width: 35, height: 35 }}>WL</Avatar>
					</Box>
				</Box>

				<DialogContent sx={{ m: 0, p: 0 }}>
					<>
						<Box
							sx={{ px: 0, pt: 0, border: 0, color: blue[600], display: "flex", flexDirection: "column", alignItems: "top", justifyContent: "top" }}
						>
							<Box sx={{ ml: 3, pt: 0 }}>
								<Box sx={{ ml: 0, display: "flex", flexDirection: "row" }}>
									<Box sx={{ ml: 0, height: size_row_height, width: size_col1, display: "flex", alignItems: "center", justifyContent: "start" }}>
										<Typography variant='body2' sx={{ color: blue[600] }}>
											{"Offer Price"}
										</Typography>
									</Box>

									<Box sx={{ ml: 0, width: size_col2, display: "flex", alignItems: "center", justifyContent: "start" }}>
										<FormatedNumberDigit variant='body2' value={offer.token_value} color={blue[600]} digit={2} />
										<Box
											component='img'
											alt='imgToken'
											src={offer.token_to_pay_img}
											sx={{
												ml: 0.5,
												my: 0,
												p: 0,
												width: size_token_to_paid,
												height: size_token_to_paid,
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										/>
										<Typography variant='body2' sx={{ ml: 0.5, color: blue[600] }}>
											{offer.token_to_pay_name.length > 7 ? offer.token_to_pay_name.slice(0, 7) + "..." : offer.token_to_pay_name}
										</Typography>
									</Box>

									<Box sx={{ ml: 0, height: size_row_height, width: size_col1, display: "flex", alignItems: "center", justifyContent: "start" }}>
										<Typography variant='body2' sx={{ color: blue[600] }}>
											{"In your Wallet"}
										</Typography>
									</Box>

									<Box sx={{ ml: 0, width: size_col4, display: "flex", alignItems: "center", justifyContent: "start" }}>
										<FormatedNumberDigit variant='body2' value={coinInMyWallet} color={blue[600]} digit={coinInMyWallet < 0.01 ? 18 : 2} />
										<Box
											component='img'
											alt='imgToken'
											src={"/images/yam.png"}
											sx={{
												ml: 0.5,
												my: 0,
												p: 0,
												width: size_token_to_paid,
												height: size_token_to_paid,
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										/>
										<Typography variant='body2' sx={{ ml: 0.5, color: blue[600] }}>
											{offer.token.shortName}
										</Typography>
									</Box>

									{/* <Box sx={{ ml: 0, width: size_col1, display: "flex", alignItems: "center", justifyContent: "start" }}></Box> */}
								</Box>
							</Box>

							<Box sx={{ ml: 3, pt: 0, height: size_row_height }}>
								<Box sx={{ ml: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
									<Box sx={{ ml: 0, height: size_row_height, width: size_col1, display: "flex", alignItems: "center", justifyContent: "start" }}>
										<Typography variant='body2' sx={{ color: blue[600] }}>
											{"Offer Price in USD"}
										</Typography>
									</Box>

									<Box sx={{ ml: 0, width: size_col2, display: "flex", alignItems: "center", justifyContent: "start" }}>
										<FormatedPrice variant='body2' value={offer.token_value_usd} color={blue[600]} />
									</Box>

									<Box sx={{ ml: 0, height: size_row_height, width: size_col1, display: "flex", alignItems: "center", justifyContent: "start" }}>
										<Typography variant='body2' sx={{ color: blue[600] }}>
											{"Autorize to spend"}
										</Typography>
									</Box>

									<Box sx={{ ml: 0, width: size_col2, display: "flex", alignItems: "center", justifyContent: "start" }}>
										{changeAllowance ? (
											<>
												<TextField
													sx={{ mt: 0, ml: 0, width: 1 }}
													// label='Search in API fullName or holder address'
													onChange={(e) => handleAllowanceChange(parseFloat(e.target.value))}
													onKeyPress={(e) => {
														if (e.key === "Enter") handleChangeAllowance();
													}}
													variant='outlined'
													type='number'
													size='small'
													value={allowance}
													onFocus={(e) => e.target.select()}
													inputProps={{
														step: 0.01,
														style: {
															textAlign: "center",
															fontSize: "14px",
														},
													}}
												/>
												{/* <Button onClick={()=> setChangeAllowance(!changeAllowance)}><EditIcon /></Button> */}
											</>
										) : (
											<>
												<FormatedNumberDigit
													variant='body2'
													value={allowanceCoin}
													color={blue[600]}
													digit={allowanceCoin === 0 ? 4 : allowanceCoin < 0.00000001 ? 18 : 8}
												/>
												{/* {allowanceCoin} */}
												<Box
													component='img'
													alt='imgToken'
													src={"/images/yam.png"}
													sx={{
														ml: 0.5,
														my: 0,
														p: 0,
														width: size_token_to_paid,
														height: size_token_to_paid,
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}
												/>
											</>
										)}
									</Box>

									{offer.whiteList && web3active && liveOffer && (
										<Box sx={{ ml: 0, width: size_col3, display: "flex", alignItems: "center", justifyContent: "start" }}>
											<>
												{changeAllowance ? (
													<>
														{waitTransaction ? (
															<Button sx={{ px: 0 }}>
																<CircularProgress size='1rem' />
															</Button>
														) : (
															<Button sx={{ px: 0 }} onClick={() => handleChangeAllowance()}>
																<CheckCircleOutlineIcon sx={{ color: green[500] }} />
															</Button>
														)}
														<Button
															sx={{ px: 0 }}
															onClick={() => {
																setChangeAllowance(!changeAllowance);
																setBuyAmount(0);
															}}
														>
															<CancelIcon sx={{ color: red[700] }} />
														</Button>
													</>
												) : (
													<Button sx={{ px: 0 }} onClick={() => setChangeAllowance(!changeAllowance)}>
														<EditIcon sx={{ color: blue[600] }} />
													</Button>
												)}
											</>
										</Box>
									)}
								</Box>
							</Box>

							<Box sx={{ ml: 3, pt: 0, height: size_row_height }}>
								<Box sx={{ ml: 0, height: size_row_height, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
									<Box sx={{ ml: 0, height: size_row_height, width: size_col1, display: "flex", alignItems: "center", justifyContent: "start" }}>
										<Typography variant='body2' sx={{ color: blue[600] }}>
											{"Offer Amount"}
										</Typography>
									</Box>

									<Box sx={{ ml: 0, width: size_col2, display: "flex", alignItems: "center", justifyContent: "start" }}>
										{liveOffer && (
											<>
												{liveOffer.amountReal === 0 || offer.token_amount === 0 ? (
													<Typography variant='body2' sx={{ ml: 0.5, color: red[600] }}>
														{"Nothing to Buy"}
													</Typography>
												) : (
													<>
														{liveOffer.amountReal * 1.0000001 < offer.token_amount || liveOffer.amountReal * 0.9999999 > offer.token_amount ? (
															<FormatedNumberDigit variant='body2' value={liveOffer.amountReal} color={red[600]} digit={4} />
														) : (
															<FormatedNumberDigit variant='body2' value={offer.token_amount} color={blue[600]} digit={4} />
														)}
														<Typography variant='body2' sx={{ ml: 0.5, color: blue[600] }}>
															{"realToken(s)"}
														</Typography>
													</>
												)}
											</>
										)}
									</Box>

									{offer.whiteList && liveOffer && (
										<>
											<Box sx={{ ml: 0, width: size_col1, display: "flex" }}>
												<Typography variant='body2' sx={{ color: blue[600] }}>
													{"Amount to Sell"}
												</Typography>
											</Box>

											<Box sx={{ ml: 0, width: size_col2, display: "flex", alignItems: "center", justifyContent: "start" }}>
												<TextField
													sx={{ mt: 0, ml: 0, width: 1 }}
													// label='Search in API fullName or holder address'
													onChange={(e) => handleBuyChange(parseFloat(e.target.value))}
													variant='outlined'
													type='number'
													size='small'
													value={buyAmount}
													onFocus={(e) => e.target.select()}
													inputProps={{
														min: 0,
														max: 100,
														step: 0.01,
														style: {
															textAlign: "center",
															fontSize: "14px",
														},
													}}
												/>
											</Box>

											<Box sx={{ ml: 0, width: size_col3, display: "flex", alignItems: "center", justifyContent: "start" }}>
												{!web3active ? (
													<Box sx={{ px: 2, cursor: "pointer" }}>
														<Avatar
															alt='Logo'
															src='/logo-pitsbi2.png'
															sx={{ m: 0, p: 0, width: logo_pitsbi_sizeW, height: logo_pitsbi_sizeH, borderRadius: 0, border: 0 }}
															onClick={() => web3functions.connect()}
														/>
													</Box>
												) : (
													<>
														<Button sx={{ px: 0 }} onClick={handleBuyMaximum}>
															MAX
														</Button>

														{!changeAllowance && buyAmount > 0 && (
															<>
																<Button sx={{ px: 0 }} onClick={handleBuyToken}>
																	<ShoppingCartIcon sx={{ color: green[600] }} />
																</Button>
																<Menu
																	anchorEl={anchorElBuyToken}
																	open={Boolean(anchorElBuyToken)}
																	// onClose={handleCloseAddToCart}
																>
																	<Box
																		sx={{
																			p: 1,
																			width: 300,
																			display: "flex",
																			flexDirection: "column",
																			alignItems: "center",
																			gap: 0, // Ajoute un espace entre les éléments
																		}}
																	>
																		<Typography variant='body2' sx={{ color: blue[600] }}>
																			{`Token to sell: ${offer.token.shortName}`}
																		</Typography>
																		<Typography variant='body2' sx={{ color: blue[600] }}>
																			{`Amount to sell: ${buyAmount}`}
																		</Typography>
																		<Typography variant='body2' sx={{ color: blue[600] }}>
																			{`You receive: ${liveOffer ? (buyAmount * liveOffer.priceReverse).toLocaleString(undefined, digitCoin) : 0} ${
																				offer.token_to_pay_name
																			}`}
																		</Typography>

																		{waitTransaction ? (
																			<Box
																				sx={{
																					mt: 1,
																					p: 1,
																					border: 2,
																					color: blue[600],
																					borderRadius: 3,
																					display: "flex",
																					flexDirection: "row",
																					justifyContent: "space-around",
																					width: "100%",
																				}}
																			>
																				<CircularProgress size='1.5rem' />
																				<Typography variant='body2' sx={{ color: blue[600] }}>
																					{`Selling some ${
																						offer.token.shortName.length > 16 ? `${offer.token.shortName.slice(0, 13)}...` : offer.token.shortName
																					}`}
																				</Typography>
																			</Box>
																		) : (
																			<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", pt: 1 }}>
																				<Button variant='contained' color={"success"} onClick={handleConfirmBuyToken}>
																					Sell Token
																				</Button>
																				<Button variant='contained' color='error' onClick={handleCloseBuyToken}>
																					Cancel
																				</Button>
																			</Box>
																		)}
																	</Box>
																</Menu>
															</>
														)}
													</>
												)}
											</Box>
										</>
									)}
								</Box>
							</Box>
						</Box>

						<Box sx={{ ml: 3, mt: 0, pt: 0, height: size_row_height }}>
						{(premium>=0.01 || premium<=-0.01) && (
							<Box sx={{ ml: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
								<Box
									sx={{
										ml: 0,
										height: size_row_height,
										width: 2 * size_col1 + size_col2,
										display: "flex",
										alignItems: "center",
										justifyContent: "start",
									}}
								>
									<Typography variant='body2' sx={{ color: blue[600] }}>
										{`You sell this realToken with a premium:`}
									</Typography>
								</Box>
								<Box sx={{ ml: 0, width: size_col2, display: "flex", alignItems: "center", justifyContent: "start" }}>
									<Typography variant='body2' sx={{ ml: 0.5, color: premium>=0?green[600]:red[700] }}>
									{`${premium>=0? "+": ""} ${premium.toFixed(2)} $`}
									</Typography>
								</Box>
							</Box>
						)}
						</Box>
					</>
				</DialogContent>

				<DialogActions sx={{ py: 0, border: 0, color: red[500] }}>
					<>
						{!changeAllowance && buyAmount > 0 ? (
							<>
								{/* <Button sx={{ px: 0 }} onClick={handleAddToCart}>
									<AddShoppingCartIcon sx={{ color: green[600] }} />
								</Button> */}
								<Button onClick={handleClose} sx={{ color: blue[600] }}>
									<CloseIcon sx={{ color: blue[600] }} />
								</Button>
								<Menu
									anchorEl={anchorElAddToCart}
									open={Boolean(anchorElAddToCart)}
									// onClose={handleCloseAddToCart}
								>
									<Box
										sx={{
											p: 1,
											width: 300,
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											gap: 0, // Ajoute un espace entre les éléments
										}}
									>
										<Typography variant='body2' sx={{ color: blue[600] }}>
											{`Add to Cart: ${offer.token.shortName}`}
										</Typography>
										<Typography variant='body2' sx={{ color: blue[600] }}>
											{`Amount to buy: ${buyAmount}`}
										</Typography>
										<Typography variant='body2' sx={{ color: blue[600] }}>
											{`You spend: ${liveOffer ? (buyAmount * liveOffer.price).toLocaleString(undefined, digitCoin) : 0} ${offer.token_to_pay_name}`}
										</Typography>
										<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", pt: 1 }}>
											<Button variant='contained' color={"success"} onClick={handleConfirmAddToCart}>
												Add to cart
											</Button>
											<Button variant='contained' color='error' onClick={handleCloseAddToCart}>
												Cancel
											</Button>
										</Box>
									</Box>
								</Menu>
							</>
						) : (
							<>
								<Button onClick={handleClose} sx={{ color: blue[600] }}>
									<CloseIcon sx={{ color: blue[600] }} />
								</Button>
							</>
						)}
					</>
				</DialogActions>
			</Box>
		</Dialog>
	);
}

export default BuyWeb3Offer;
